// User Service - Contains the api endpoints to the backend to grab user information.

import { fetchWithRetries, baseUri, fetchWrap } from 'common/utilities/fetchWrap';


 /**
 * @description Get's the current authenticated user that is connected via the backend and has authenticated to genesys cloud.
 * @return {*} Current User
 */
export const getSelf = () => {
    const url = `${baseUri()}/users/me`;
    return fetchWithRetries(url, {
        method: 'GET'
    }, '', false, true);
};

/**
 * @description Get's a full list of users from the organization.
 * @return {*} List User entities.
 */
export const getUsers = () => {
    const url = `${baseUri()}/users`;
    return fetchWithRetries(url, {
        method: 'GET'
    }, '', false, true);
};

/**
 * @description Get's a user's queues
 * @return {*} List User queues.
 */
export const getUserQueues = (id) => {
    const url = `${baseUri()}/users/${id}/queues`;
    return fetchWithRetries(url, {
        method: 'GET'
    }, '', false, true);
};

/**
 * @description Gets the current list of authorized queues and exports them, starts a job for export.
 * @return {object} 
 */
export const getUsersExport = () => {
    const url = `${baseUri()}/users/export`;
    return fetchWithRetries(url, {
        method: 'GET'
    }, '', false, true);
};

/**
 * @description Posts the current list of authorized queues and imports them, starts a job for import.
 * @return {object} 
 */
export const postUsersImport = (fields) => {
    const url = `${baseUri()}/users/import`;
    return fetchWithRetries(url, {
        method: 'POST',
        body: JSON.stringify({
            ...fields
        })
    }, '', false, true);
};


export default {
    getSelf,
    getUsers
}