import * as React from 'react';
import { Route, Routes } from 'react-router-dom';

const CXNowPage = React.lazy(() => import('pages/cxnow/CXNow'));

const CXNowBackupPage = React.lazy(() => import('pages/cxnow/backup/Backup'));
const CXNowExportPage = React.lazy(() => import('pages/cxnow/export/Export'));
const CXNowImportPage = React.lazy(() => import('pages/cxnow/import/Import'));
const CXNowDisasterRecoveryPage = React.lazy(() => import('pages/cxnow/disaster-recovery/DisasterRecovery'));
const CXNowSchedulesListPage = React.lazy(() => import('pages/cxnow/disaster-recovery/SchedulesList'));
const CXNowOrgValidationPage = React.lazy(() => import('pages/cxnow/org-validation/orgValidation'));

const PageNotFoundPage = React.lazy(() => import('pages/PageNotFound'));

const CXNowRouter = ({ jobsUpdates, setJobsUpdates }) => {

    return (
        <Routes>
            <Route index element={<CXNowPage />} />
            <Route path="backup" element={<CXNowBackupPage />} />
            <Route path="export" element={<CXNowExportPage />} />
            <Route path="import" element={<CXNowImportPage />} />
            <Route path="disaster-recovery" element={<CXNowDisasterRecoveryPage />} />
            <Route path="schedules-list" element={<CXNowSchedulesListPage jobsUpdates={jobsUpdates} setJobsUpdates={setJobsUpdates} />} />
            <Route path="org-validation" element={<CXNowOrgValidationPage />} />
            <Route path="*" element={<PageNotFoundPage />} />
        </Routes>
    );
};

export default CXNowRouter;