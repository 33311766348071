import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';

import {
    List,
    ListItemIcon, ListItemText, 
    ListItemButton,
    Collapse
} from '@mui/material';

import { Business, SettingsOutlined, TravelExploreOutlined, PeopleOutlined, PhoneOutlined, NotificationsOutlined, AccountTreeOutlined, EventNoteOutlined, GetAppOutlined, UpgradeOutlined, ExpandLessOutlined, ExpandMoreOutlined } from '@mui/icons-material';


const OrganizationSidebar = () => {
    const isMounted = useRef(true);
    const navigate = useNavigate();
    const location = useLocation();

    const [orgToggle, setOrgToggle] = useState(true);

    useEffect(() => {
        if (isMounted && location && location.pathname !== '') {
            if (location.pathname === '/') {
                setOrgToggle(false);
            } else if (location.pathname.includes('organization/') || location.pathname.includes('organization')) {
                setOrgToggle(true);
            }
        }

        return () => {
            isMounted.current = false;
        }
    }, [location.pathname])

    const handleOrgToggle = (event) => {
        event.preventDefault();
        if (!location.pathname.includes('organization') || location.pathname.includes('organization/')) {
            navigate('/organization');
            setOrgToggle(true);
        } else {
            setOrgToggle(!orgToggle);
        }
    }

    return (
        <Fragment>
            <ListItemButton component={Link} key={'Organization'} selected={location.pathname.includes('organization')} onClick={handleOrgToggle}>
                <ListItemIcon>
                    <Business />
                </ListItemIcon>
                <ListItemText primary={'GC Accelerator'} />
                {orgToggle ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
            </ListItemButton>
            <Collapse in={orgToggle} timeout="auto">
                <List component="div" disablePadding>
                    <ListItemButton component={Link} to={`/organization/settings`} key={'Org-Settings'} selected={location.pathname.includes('organization/settings')} sx={{ pl: 4 }}>
                        <ListItemIcon>
                            <SettingsOutlined />
                        </ListItemIcon>
                        <ListItemText primary="Settings" />
                    </ListItemButton>
                    <ListItemButton component={Link} to={`/organization/locations`} key={'Org-Locations'} selected={location.pathname.includes('organization/locations')} sx={{ pl: 4 }}>
                        <ListItemIcon>
                            <TravelExploreOutlined />
                        </ListItemIcon>
                        <ListItemText primary="Locations" />
                    </ListItemButton>
                    <ListItemButton component={Link} to={`/organization/people`} key={'Org-People'} selected={location.pathname.includes('organization/people')} sx={{ pl: 4 }}>
                        <ListItemIcon>
                            <PeopleOutlined />
                        </ListItemIcon>
                        <ListItemText primary="People" />
                    </ListItemButton>
                    <ListItemButton component={Link} to={`/organization/telephony`} key={'Org-Telephony'} selected={location.pathname.includes('organization/telephony')} sx={{ pl: 4 }}>
                        <ListItemIcon>
                            <PhoneOutlined />
                        </ListItemIcon>
                        <ListItemText primary="Telephony" />
                    </ListItemButton>
                    <ListItemButton component={Link} to={`/organization/announcements`} key={'Org-Announcements'} selected={location.pathname.includes('organization/announcements')} sx={{ pl: 4 }}>
                        <ListItemIcon>
                            <NotificationsOutlined />
                        </ListItemIcon>
                        <ListItemText primary="Announcements" />
                    </ListItemButton>
                    <ListItemButton component={Link} to={`/organization/callflows`} key={'Org-CallFlows'} selected={location.pathname.includes('organization/callflows')} sx={{ pl: 4 }}>
                        <ListItemIcon>
                            <AccountTreeOutlined />
                        </ListItemIcon>
                        <ListItemText primary="Flows" />
                    </ListItemButton>
                    <ListItemButton component={Link} to={`/organization/schedules-routing`} key={'Org-SchedulesRouting'} selected={location.pathname.includes('organization/schedules-routing')} sx={{ pl: 4 }}>
                        <ListItemIcon>
                            <EventNoteOutlined />
                        </ListItemIcon>
                        <ListItemText primary="Schedules and Routing" />
                    </ListItemButton>
                    <ListItemButton component={Link} to={`/organization/export`} key={'Org-Export'} selected={location.pathname.includes('organization/export')} sx={{ pl: 4 }}>
                        <ListItemIcon>
                            <GetAppOutlined />
                        </ListItemIcon>
                        <ListItemText primary="Export" />
                    </ListItemButton>
                    {/* <ListItemButton component={Link} to={`/organization/outbound`} key={'Org-Outbound'} selected={location.pathname.includes('organization/outbound')} sx={{ pl: 4 }}>
                        <ListItemIcon>
                            <Campaign />
                        </ListItemIcon>
                        <ListItemText primary="Outbound" />
                    </ListItemButton> */}
                    <ListItemButton component={Link} to={`/organization/import`} key={'Org-Import'} selected={location.pathname.includes('organization/import')} sx={{ pl: 4 }}>
                        <ListItemIcon>
                            <UpgradeOutlined />
                        </ListItemIcon>
                        <ListItemText primary="Import" />
                    </ListItemButton>
                </List>
            </Collapse>
        </Fragment>
    )
};

export default OrganizationSidebar;