import { fetchWithRetries, baseUri } from 'common/utilities/fetchWrap';

/**
 * @async
 * @description Gets the current configuration items from the ignite node backend.
 * @return {Promise<Array>} 
 */
export const getConfiguration = () => {
    const url = `${baseUri()}/config`;
    return fetchWithRetries(url, {
        method: 'GET'
    }, '', false, true);
};


export default {
    getConfiguration
}