import { fetchWithRetries, baseUri } from 'common/utilities/fetchWrap';

/**
 * @description Gets the current users audit information.
 * @return {Array} 
 */
export const getUserAudit = () => {
    const url = `${baseUri()}/audit/me`;
    return fetchWithRetries(url, {
        method: 'GET'
    }, '', false, true);
};

/**
 * @description Gets the current organization audit information.
 * @return {Array} 
 */
export const getOrganizationAudit = () => {
    const url = `${baseUri()}/audit`;
    return fetchWithRetries(url, {
        method: 'GET'
    }, '', false, true);
};


export default {
    getUserAudit,
    getOrganizationAudit
}