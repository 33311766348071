const GlobalAuthenticatedContextModel = {
    configuration: {},
    activity: [],
    
    grants: {},
    user: {},
    genesys: {},
    
    modules: [],
    activeModules: [],
    admins: [],

    sessionLoaded: false,
    userLoaded: false,
    contextLoaded: false,
    inGCloud: false,

    appFoundry: false,
    appFoundryRecord: {},
    navigateToAF: false,

    internalSubscription: false,
    internalSubscriptionError: false,
    
    bypass: false,

    isAdmin: false,
    isGenesysEmployee: false
};

export default GlobalAuthenticatedContextModel;