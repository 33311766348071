import { fetchWithRetries, baseUri } from 'common/utilities/fetchWrap';

/**
 * @description Gets the current users activity across multiple organizations pk (email).
 * @return {Array} 
 */
export const getActivityMe = () => {
    const url = `${baseUri()}/users/me/activity`;
    return fetchWithRetries(url, {
        method: 'GET'
    }, '', false, true);
};

/**
 * @description Gets the user activity across multiple organizations. Fails if not admin
 * @return {Array} 
 */
export const getAllActivity = () => {
    const url = `${baseUri()}/users/activity`;
    return fetchWithRetries(url, {
        method: 'GET'
    }, '', false, true);
};

export default {
    getActivityMe, getAllActivity
}