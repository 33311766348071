// Global CSS
import './index.css'

// Global CSS Fonts
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

// Imports 
import * as React from 'react'
import * as ReactDOM from 'react-dom/client'

// Application
import ApplicationRouter from 'pages/ApplicationRouter'

const root = ReactDOM.createRoot(
    document.getElementById('root')
);
root.render(<ApplicationRouter />);
