// React
import * as React from 'react';
import { useNavigate } from 'react-router';

// Third Party
import { Box, Button, Grid, Typography, LinearProgress, Divider, Stack, 
    Card, CardMedia, CardContent, CardActions, CardHeader, Avatar, 
    TableContainer, Paper, Table, TableHead, TableRow, TableBody, TableCell, Alert 
} from '@mui/material';
import { Business, CloudSync, Quiz, Settings, Widgets, Close } from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';

// Context
import GlobalAuthenticatedContext from 'common/contexts/GlobalAuthenticatedContext';
import GlobalAuthenticatedContextModel from 'common/contexts/GlobalAuthenticatedContextModel';

// Services
import { getUserAudit } from 'services/api/auditService';

// CSS
import './Entry.css';

// Utilities
import { getStorageItem, setStorageItem } from 'common/utilities/applicationStorage';

// Constants
import { OrganizationConstants, IgniteConstants } from 'common/utilities/appConstants';

const Entry = ({}) => {
    const navigate = useNavigate();

    const { globalAuthenticated, setGlobalAuthenticated } = React.useContext(GlobalAuthenticatedContext);

    const [userInfo, setUserInfo] = React.useState(GlobalAuthenticatedContextModel.user);
    const [orgInfo, setOrgInfo] = React.useState(GlobalAuthenticatedContextModel.genesys);
    const [orgEnv, setOrgEnv] = React.useState('');
    const [dismissedWarning, setDismissedWarning] = React.useState(true);

    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [forbidden, setForbidden] = React.useState(false);

    const [orgMetadata, setOrgMetadata] = React.useState([]);
    const [orgFeatures, setOrgFeatures] = React.useState([]);

    const [isBCPActive, setIsBCPActive] = React.useState(false);
    const [isGCAActive, setIsGCAActive] = React.useState(false);

    React.useEffect(() => {
        if (globalAuthenticated && globalAuthenticated.contextLoaded) {
            setUserInfo(globalAuthenticated.user);
            setOrgInfo(globalAuthenticated.genesys);
            const env = getStorageItem(OrganizationConstants.UrlAuthShort, undefined, undefined, sessionStorage);
            setOrgEnv(env);
            const dismissed = getStorageItem(IgniteConstants.UserDismissedWarning, undefined, undefined, sessionStorage);
            setDismissedWarning(dismissed || false);

            let resultBCP = false;
            let resultGCA = false;

            if (globalAuthenticated.bypass) {
                resultBCP = true;
                resultGCA = true;
            } else {
                if (globalAuthenticated.internalSubscription && globalAuthenticated.activeModules && globalAuthenticated.activeModules.length > 0) {
                    const isBCPThere = globalAuthenticated.activeModules.find(f => f.moduleName === 'ignite-bcp');
                    if (isBCPThere) resultBCP = true;

                    const isGCAThere = globalAuthenticated.activeModules.find(f => f.moduleName === 'ignite-gca');
                    if (isGCAThere) resultGCA = true;
                }

                if (globalAuthenticated.appFoundry) {
                    resultBCP = true;
                    resultGCA = false;
                }
            }

            setIsBCPActive(resultBCP);
            setIsGCAActive(resultGCA);
        }
    }, [globalAuthenticated, globalAuthenticated.internalSubscription, globalAuthenticated.activeModules, globalAuthenticated.bypass, globalAuthenticated.appFoundry]);

    React.useEffect(() => {
        if (globalAuthenticated && globalAuthenticated.contextLoaded && orgInfo) {
            createMetadataRows();
            createFeatureRows();
        }
    }, [globalAuthenticated, orgInfo])

    const createData = (title, value) => {
        return Object.assign({}, { title, value });
    };

    const createMetadataRows = () => {
        if (orgInfo) {
            const meta = new Set([
                createData('Organization ID', orgInfo.id),
                createData('Default Site ID', orgInfo.defaultSiteId),
                createData('Default Country Code', orgInfo.defaultCountryCode),
                createData('Default Language', orgInfo.defaultLanguage),
                createData('Deletable', orgInfo.deletable ? 'Yes' : 'No'),
                createData('Domain', orgInfo.domain),
                createData('Product Platform', orgInfo.productPlatform),
                createData('State', orgInfo.state),
                createData('Version', orgInfo.version)
            ]);

            setOrgMetadata(meta);
        }
    };

    const createFeatureRows = () => {
        if (orgInfo && orgInfo.features) {
            const meta = new Set([
                createData('Chat', orgInfo.features?.chat ? 'Yes' : 'No'),
                createData('Contact Center', orgInfo.features?.contactCenter ? 'Yes' : 'No'),
                createData('Directory', orgInfo.features?.directory ? 'Yes' : 'No'),
                createData('HIPAA', orgInfo.features.hipaa ? 'Yes' : 'No'),
                createData('PCI', orgInfo.features.pci ? 'Yes' : 'No'),
                createData('Informal Photos', orgInfo.features.informalPhotos ? 'Yes' : 'No'),
                createData('PureCloud', orgInfo.features.purecloud ? 'Yes' : 'No'),
                createData('PureCloud Voice', orgInfo.features.purecloudVoice ? 'Yes' : 'No'),
                createData('Realtime CIC', orgInfo.features.realtimeCIC ? 'Yes' : 'No'),
                createData('Unified Communications', orgInfo.features.unifiedCommunications ? 'Yes' : 'No'),
                createData('XMPP Federation', orgInfo.features.xmppFederation ? 'Yes' : 'No')
            ]);

            setOrgFeatures(meta);
        }
    };

    const handNavigateToCX = () => {
        navigate('cxnow');
    };

    const handleNavigateToOrg = () => {
        navigate('organization');
    };

    const handleNavigateToGuide = () => {
        navigate('guide');
    };

    const handleNavigateToChangelog = () => {
        navigate('changelog');
    };

    const handleMailTo = (e) => {
        if (window) {
            window.location.href = "mailto:IgniteSupport@genesys.com?subject=[Ignite] Help Request&body=See Genesys Operations Automation Tooling user guide for more details regarding what to include in the body of this email.";
            e.preventDefault();
        }
        return;
    };

    const handleDismissLoginWarning = () => {
        setStorageItem(IgniteConstants.UserDismissedWarning, true, undefined, undefined, sessionStorage);
        setDismissedWarning(true);
    };

    if (forbidden) return <Navigate to={'/unauthorized'} />

    if (loading || (globalAuthenticated && !globalAuthenticated.contextLoaded)) {
        return (
            <React.Fragment>
                <Box sx={{ my: 1, mx: 1 }}>
                    <LinearProgress color="secondary" />
                </Box>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
        {error && <ErrorFound error={error} />}
            <section>
                <Box sx={{ my: 1, mx: 1}}>
                    <Grid container direction='row' justifyContent="space-between" alignItems="center">
                        <Grid item xs>
                            <Typography variant="h4" color="primary" component="div">
                                Welcome, {userInfo.name}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction='row' justifyContent="space-between" alignItems="top" sx={{ mb: 1 }}>
                        <Grid item xs>
                            <Typography variant="body2" color="text.secondary" component="div">
                                Get started with configuration changes: <span className='brand-color-orange'>Export</span>, <span className='brand-color-orange'>Import</span>, or utilize <span className='brand-color-orange'>Business Continuity Plan</span> to Backup Genesys Cloud Organizational changes.
                                {/* Get started with configuration changes: <span className='brand-color-orange'>Export</span>, <span className='brand-color-orange'>Import</span>. */}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider />
                    {dismissedWarning != true &&
                        <Alert severity="warning" sx={{ mt: 1, mb: 2, mr: 2 }}>
                            <Typography variant="body" component="div" sx={{ fontSize: "1.3em"}}>
                                <strong>Please Note:</strong> This tool utilizes your Genesys Cloud credentials. Therefore existing browser or web pages with Genesys Cloud authentication have the potential to interrupt the application session, and should be used with caution.
                            </Typography>
                            <Button variant="outlined"  size="small" color="error" sx={{mt: 1}}
                                onClick={handleDismissLoginWarning}><Close fontSize='small' />&nbsp; Dismiss</Button>
                        </Alert>
                    }
                    <Box sx={{ mt: 1, overflowY: 'scroll', height: `calc(100vh - ${!dismissedWarning ? 16 : 7}em)` }}>
                        <Stack direction="row" spacing={2} sx={{ mt: 2, ml: 1, mb: 1 }} flexWrap={'wrap'} useFlexGap className='ignite-entry'>
                            {isGCAActive && (
                                <Card sx={{ maxWidth: 600, minHeight: 400 }} elevation={3}>
                                    <CardHeader avatar={
                                        <Avatar elevation={1} sx={{ bgcolor: 'white', border: '2px solid grey' }} aria-label="Genesys Cloud Accelerator">
                                            <Business className='brand-color-orange' />
                                        </Avatar>
                                    } title="Genesys Cloud Accelerator" subheader="Quickly Export, Import, Manage Existing Configuration" />
                                    <CardMedia
                                        sx={{ height: 250 }}
                                        image="/resources/jpg/genesys-cloud-accelerator.png"
                                        title="Genesys Cloud Accelerator"
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="div">
                                            Get Started
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            Quickly manage configuration for existing entities throughout your organization. Export and import
                                            various configuration throughout settings, locations, people and users, flows, prompts, and schedule and routing.
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Button size="small" onClick={handleNavigateToOrg}>Manage</Button>
                                    </CardActions>
                                </Card>
                            )}
                            {isBCPActive && (
                                <Card sx={{ maxWidth: 600, minHeight: 400 }} elevation={3}>
                                    <CardHeader avatar={
                                        <Avatar elevation={1} sx={{ bgcolor: 'white', border: '2px solid grey' }} aria-label="Business Continuity Plan">
                                            <CloudSync className='brand-color-orange' />
                                        </Avatar>
                                    } title="Business Continuity Plan" subheader="Backup, Export, Import; Disaster Recovery" />
                                    <CardMedia
                                        sx={{ height: 250 }}
                                        image="/resources/jpg/genesys-cloud-cx.jpg"
                                        title="Business Continuity Plan"
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="div">
                                            Transform
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            Manage and schedule backups, provide import and export of configuration utilizing
                                            Genesys Cloud's CX. Provide disaster recovery to additional staged environments.
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Button size="small" onClick={handNavigateToCX}>Manage</Button>
                                    </CardActions>
                                </Card>
                            )}
                            <Card sx={{ maxWidth: 500, minHeight: 400 }} elevation={3}>
                                <CardHeader avatar={
                                    <Avatar elevation={1} sx={{ bgcolor: 'white', border: '2px solid grey' }} aria-label="Genesys Cloud Accelerator">
                                        <Settings className='brand-color-orange' />
                                    </Avatar>
                                } title={orgInfo.name} subheader={"Environment: " + orgEnv} />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        Metadata
                                    </Typography>
                                    <TableContainer component={Paper}>
                                        <Table sx={{ bgcolor: 'white' }} size="small" aria-label="a dense table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Information</TableCell>
                                                    <TableCell align="right">Value</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {orgMetadata && Array.from(orgMetadata).map((row) => (
                                                    <TableRow
                                                        key={uuidv4()}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            {row.title}
                                                        </TableCell>
                                                        <TableCell align="right">{row.value}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </CardContent>
                            </Card>
                            <Card sx={{ maxWidth: 500, minHeight: 400 }} elevation={3}>
                                <CardHeader avatar={
                                    <Avatar elevation={1} sx={{ bgcolor: 'white', border: '2px solid grey' }} aria-label="Org Features">
                                        <Widgets className='brand-color-orange' />
                                    </Avatar>
                                } title={orgInfo.name} subheader={"Environment: " + orgEnv} />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        Features
                                    </Typography>
                                    <TableContainer component={Paper}>
                                        <Table sx={{ bgcolor: 'white' }} size="small" aria-label="a dense table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Information</TableCell>
                                                    <TableCell align="right">Value</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {orgFeatures && Array.from(orgFeatures).map((row) => (
                                                    <TableRow
                                                        key={uuidv4()}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            {row.title}
                                                        </TableCell>
                                                        <TableCell align="right">{row.value}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </CardContent>
                            </Card>
                            <Card sx={{ maxWidth: 300, maxHeight: 350 }} elevation={3}>
                                <CardHeader avatar={
                                    <Avatar elevation={1} sx={{ bgcolor: 'white', border: '2px solid grey' }} aria-label="Need Help">
                                        <Quiz className='brand-color-orange' />
                                    </Avatar>
                                } title="Need Help?" subheader="Guide, Changelog" />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        Looking for Help?
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Quickly check out the Guide or Changelog for information regarding recent
                                        changes to the application or to find helpful tips in navigating; knowledge.
                                        <br /><br />
                                        Additionally from the Profile dropdown, reach out via <strong>Help</strong> by
                                        sending a message directly to the team.
                                    </Typography>
                                </CardContent>
                                <CardActions sx={{ mt: 'auto' }}>
                                    <Button size="small" onClick={handleNavigateToGuide}>Guide</Button>
                                    <Button size="small" onClick={handleNavigateToChangelog}>Changelog</Button>
                                    <Button size="small" onClick={handleMailTo}>Help</Button>
                                </CardActions>
                            </Card>
                        </Stack>
                    </Box>
                </Box>
            </section>
        </React.Fragment>
    )
};

export default Entry;