
// Authentication
const AuthenticationConstants = {
    CodeToken: 'auth-token',
    IGToken: 'auth-token-ig',
    Key: 'authenticated',
    SecureStorageSource: 'auth-source',
    SecureStorageDestination: 'auth-destination'
};

// Site Fuctionality
const IgniteConstants = {
    UserDismissedWarning: 'ignite-user-dismissed-warning'
}

// Organization
const OrganizationConstants = {
    UrlApps: 'org-apps',
    UrlApi: 'org-api',
    UrlAuth: 'org-auth',
    UrlAuthShort: 'org-auth-short',
    UrlPromptLogin: 'org-auth-prompt-login',
};

// Jobs
const JobsConstants = {
    Visit: 'job-visit',
    MessageSent: 'job-has-finished'
};

// Divisions
const DivisionsConstants = {
    ImportJobs: 'division-import-jobs'
};

// Skills
const SkillsConstants = {
    ImportJobs: 'skill-import-jobs'
};

// Wrapup Codes
const WrapUpCodesConstants = {
    ImportJobs: 'wrapupcode-import-jobs'
};

// Skills
const RolesConstants = {
    ImportJobs: 'role-import-jobs'
};

// Locations
const LocationsConstants = {
    ImportJobs: 'location-import-jobs',
    Countries: [
        { label: "Afghanistan", id: "AF" },
        { label: "Åland Islands", id: "AX" },
        { label: "Albania", id: "AL" },
        { label: "Algeria", id: "DZ" },
        { label: "American Samoa", id: "AS" },
        { label: "Andorra", id: "AD" },
        { label: "Angola", id: "AO" },
        { label: "Anguilla", id: "AI" },
        { label: "Antarctica", id: "AQ" },
        { label: "Antigua and Barbuda", id: "AG" },
        { label: "Argentina", id: "AR" },
        { label: "Armenia", id: "AM" },
        { label: "Aruba", id: "AW" },
        { label: "Australia", id: "AU" },
        { label: "Austria", id: "AT" },
        { label: "Azerbaijan", id: "AZ" },
        { label: "Bahamas", id: "BS" },
        { label: "Bahrain", id: "BH" },
        { label: "Bangladesh", id: "BD" },
        { label: "Barbados", id: "BB" },
        { label: "Belarus", id: "BY" },
        { label: "Belgium", id: "BE" },
        { label: "Belize", id: "BZ" },
        { label: "Benin", id: "BJ" },
        { label: "Bermuda", id: "BM" },
        { label: "Bhutan", id: "BT" },
        { label: "Bolivia", id: "BO" },
        { label: "Bosnia and Herzegovina", id: "BA" },
        { label: "Botswana", id: "BW" },
        { label: "Bouvet Island", id: "BV" },
        { label: "Brazil", id: "BR" },
        { label: "British Indian Ocean Territory", id: "IO" },
        { label: "Brunei Darussalam", id: "BN" },
        { label: "Bulgaria", id: "BG" },
        { label: "Burkina Faso", id: "BF" },
        { label: "Burundi", id: "BI" },
        { label: "Cambodia", id: "KH" },
        { label: "Cameroon", id: "CM" },
        { label: "Canada", id: "CA" },
        { label: "Cape Verde", id: "CV" },
        { label: "Cayman Islands", id: "KY" },
        { label: "Central African Republic", id: "CF" },
        { label: "Chad", id: "TD" },
        { label: "Chile", id: "CL" },
        { label: "China", id: "CN" },
        { label: "Christmas Island", id: "CX" },
        { label: "Cocos (Keeling) Islands", id: "CC" },
        { label: "Colombia", id: "CO" },
        { label: "Comoros", id: "KM" },
        { label: "Congo", id: "CG" },
        { label: "Congo, The Democratic Republic of the", id: "CD" },
        { label: "Cook Islands", id: "CK" },
        { label: "Costa Rica", id: "CR" },
        { label: "Cote D\'Ivoire", id: "CI" },
        { label: "Croatia", id: "HR" },
        { label: "Cuba", id: "CU" },
        { label: "Cyprus", id: "CY" },
        { label: "Czech Republic", id: "CZ" },
        { label: "Denmark", id: "DK" },
        { label: "Djibouti", id: "DJ" },
        { label: "Dominica", id: "DM" },
        { label: "Dominican Republic", id: "DO" },
        { label: "Ecuador", id: "EC" },
        { label: "Egypt", id: "EG" },
        { label: "El Salvador", id: "SV" },
        { label: "Equatorial Guinea", id: "GQ" },
        { label: "Eritrea", id: "ER" },
        { label: "Estonia", id: "EE" },
        { label: "Ethiopia", id: "ET" },
        { label: "Falkland Islands (Malvinas)", id: "FK" },
        { label: "Faroe Islands", id: "FO" },
        { label: "Fiji", id: "FJ" },
        { label: "Finland", id: "FI" },
        { label: "France", id: "FR" },
        { label: "French Guiana", id: "GF" },
        { label: "French Polynesia", id: "PF" },
        { label: "French Southern Territories", id: "TF" },
        { label: "Gabon", id: "GA" },
        { label: "Gambia", id: "GM" },
        { label: "Georgia", id: "GE" },
        { label: "Germany", id: "DE" },
        { label: "Ghana", id: "GH" },
        { label: "Gibraltar", id: "GI" },
        { label: "Greece", id: "GR" },
        { label: "Greenland", id: "GL" },
        { label: "Grenada", id: "GD" },
        { label: "Guadeloupe", id: "GP" },
        { label: "Guam", id: "GU" },
        { label: "Guatemala", id: "GT" },
        { label: "Guernsey", id: "GG" },
        { label: "Guinea", id: "GN" },
        { label: "Guinea-Bissau", id: "GW" },
        { label: "Guyana", id: "GY" },
        { label: "Haiti", id: "HT" },
        { label: "Heard Island and Mcdonald Islands", id: "HM" },
        { label: "Holy See (Vatican City State)", id: "VA" },
        { label: "Honduras", id: "HN" },
        { label: "Hong Kong", id: "HK" },
        { label: "Hungary", id: "HU" },
        { label: "Iceland", id: "IS" },
        { label: "India", id: "IN" },
        { label: "Indonesia", id: "id" },
        { label: "Iran, Islamic Republic Of", id: "IR" },
        { label: "Iraq", id: "IQ" },
        { label: "Ireland", id: "IE" },
        { label: "Isle of Man", id: "IM" },
        { label: "Israel", id: "IL" },
        { label: "Italy", id: "IT" },
        { label: "Jamaica", id: "JM" },
        { label: "Japan", id: "JP" },
        { label: "Jersey", id: "JE" },
        { label: "Jordan", id: "JO" },
        { label: "Kazakhstan", id: "KZ" },
        { label: "Kenya", id: "KE" },
        { label: "Kiribati", id: "KI" },
        { label: "Korea, Democratic People\'s Republic of", id: "KP" },
        { label: "Korea, Republic of", id: "KR" },
        { label: "Kosovo", id: "XK" },
        { label: "Kuwait", id: "KW" },
        { label: "Kyrgyzstan", id: "KG" },
        { label: "Lao People\'s Democratic Republic", id: "LA" },
        { label: "Latvia", id: "LV" },
        { label: "Lebanon", id: "LB" },
        { label: "Lesotho", id: "LS" },
        { label: "Liberia", id: "LR" },
        { label: "Libyan Arab Jamahiriya", id: "LY" },
        { label: "Liechtenstein", id: "LI" },
        { label: "Lithuania", id: "LT" },
        { label: "Luxembourg", id: "LU" },
        { label: "Macao", id: "MO" },
        { label: "Macedonia, The Former Yugoslav Republic of", id: "MK" },
        { label: "Madagascar", id: "MG" },
        { label: "Malawi", id: "MW" },
        { label: "Malaysia", id: "MY" },
        { label: "Maldives", id: "MV" },
        { label: "Mali", id: "ML" },
        { label: "Malta", id: "MT" },
        { label: "Marshall Islands", id: "MH" },
        { label: "Martinique", id: "MQ" },
        { label: "Mauritania", id: "MR" },
        { label: "Mauritius", id: "MU" },
        { label: "Mayotte", id: "YT" },
        { label: "Mexico", id: "MX" },
        { label: "Micronesia, Federated States of", id: "FM" },
        { label: "Moldova, Republic of", id: "MD" },
        { label: "Monaco", id: "MC" },
        { label: "Mongolia", id: "MN" },
        { label: "Montenegro", id: "ME" },
        { label: "Montserrat", id: "MS" },
        { label: "Morocco", id: "MA" },
        { label: "Mozambique", id: "MZ" },
        { label: "Myanmar", id: "MM" },
        { label: "Namibia", id: "NA" },
        { label: "Nauru", id: "NR" },
        { label: "Nepal", id: "NP" },
        { label: "Netherlands", id: "NL" },
        { label: "Netherlands Antilles", id: "AN" },
        { label: "New Caledonia", id: "NC" },
        { label: "New Zealand", id: "NZ" },
        { label: "Nicaragua", id: "NI" },
        { label: "Niger", id: "NE" },
        { label: "Nigeria", id: "NG" },
        { label: "Niue", id: "NU" },
        { label: "Norfolk Island", id: "NF" },
        { label: "Northern Mariana Islands", id: "MP" },
        { label: "Norway", id: "NO" },
        { label: "Oman", id: "OM" },
        { label: "Pakistan", id: "PK" },
        { label: "Palau", id: "PW" },
        { label: "Palestinian Territory, Occupied", id: "PS" },
        { label: "Panama", id: "PA" },
        { label: "Papua New Guinea", id: "PG" },
        { label: "Paraguay", id: "PY" },
        { label: "Peru", id: "PE" },
        { label: "Philippines", id: "PH" },
        { label: "Pitcairn", id: "PN" },
        { label: "Poland", id: "PL" },
        { label: "Portugal", id: "PT" },
        { label: "Puerto Rico", id: "PR" },
        { label: "Qatar", id: "QA" },
        { label: "Reunion", id: "RE" },
        { label: "Romania", id: "RO" },
        { label: "Russian Federation", id: "RU" },
        { label: "Rwanda", id: "RW" },
        { label: "Saint Helena", id: "SH" },
        { label: "Saint Kitts and Nevis", id: "KN" },
        { label: "Saint Lucia", id: "LC" },
        { label: "Saint Pierre and Miquelon", id: "PM" },
        { label: "Saint Vincent and the Grenadines", id: "VC" },
        { label: "Samoa", id: "WS" },
        { label: "San Marino", id: "SM" },
        { label: "Sao Tome and Principe", id: "ST" },
        { label: "Saudi Arabia", id: "SA" },
        { label: "Senegal", id: "SN" },
        { label: "Serbia", id: "RS" },
        { label: "Seychelles", id: "SC" },
        { label: "Sierra Leone", id: "SL" },
        { label: "Singapore", id: "SG" },
        { label: "Slovakia", id: "SK" },
        { label: "Slovenia", id: "SI" },
        { label: "Solomon Islands", id: "SB" },
        { label: "Somalia", id: "SO" },
        { label: "South Africa", id: "ZA" },
        { label: "South Georgia and the South Sandwich Islands", id: "GS" },
        { label: "Spain", id: "ES" },
        { label: "Sri Lanka", id: "LK" },
        { label: "Sudan", id: "SD" },
        { label: "Suriname", id: "SR" },
        { label: "Svalbard and Jan Mayen", id: "SJ" },
        { label: "Swaziland", id: "SZ" },
        { label: "Sweden", id: "SE" },
        { label: "Switzerland", id: "CH" },
        { label: "Syrian Arab Republic", id: "SY" },
        { label: "Taiwan", id: "TW" },
        { label: "Tajikistan", id: "TJ" },
        { label: "Tanzania, United Republic of", id: "TZ" },
        { label: "Thailand", id: "TH" },
        { label: "Timor-Leste", id: "TL" },
        { label: "Togo", id: "TG" },
        { label: "Tokelau", id: "TK" },
        { label: "Tonga", id: "TO" },
        { label: "Trinidad and Tobago", id: "TT" },
        { label: "Tunisia", id: "TN" },
        { label: "Turkey", id: "TR" },
        { label: "Turkmenistan", id: "TM" },
        { label: "Turks and Caicos Islands", id: "TC" },
        { label: "Tuvalu", id: "TV" },
        { label: "Uganda", id: "UG" },
        { label: "Ukraine", id: "UA" },
        { label: "United Arab Emirates", id: "AE" },
        { label: "United Kingdom", id: "GB" },
        { label: "United States", id: "US" },
        { label: "United States Minor Outlying Islands", id: "UM" },
        { label: "Uruguay", id: "UY" },
        { label: "Uzbekistan", id: "UZ" },
        { label: "Vanuatu", id: "VU" },
        { label: "Venezuela", id: "VE" },
        { label: "Vietnam", id: "VN" },
        { label: "Virgin Islands, British", id: "VG" },
        { label: "Virgin Islands, U.S.", id: "VI" },
        { label: "Wallis and Futuna", id: "WF" },
        { label: "Western Sahara", id: "EH" },
        { label: "Yemen", id: "YE" },
        { label: "Zambia", id: "ZM" },
        { label: "Zimbabwe", id: "ZW" }        
    ]
};

// Sites
const SitesConstants = {
    ImportJobs: 'site-import-jobs',
    MediaModelOptions: [
        { label: "Cloud" },
        { label: "Premises" }
    ],
    MediaRegionOptions: [
        { label: "Africa (Cape Town)", id: "af-south-1" },
        { label: "Asia Pacific (Hong Kong)", id: "ap-east-1" },
        { label: "Asia Pacific (Tokyo)", id: "ap-northeast-1" },
        { label: "Asia Pacific (Seoul)", id: "ap-northeast-2" },
        { label: "Asia Pacific (Mumbai)", id: "ap-south-1" },
        { label: "Asia Pacific (Sydney)", id: "ap-southeast-2" },
        { label: "Asia Pacific (Jakarta)", id: "ap-southeast-3" },
        { label: "Americas (Canada)", id: "ca-central-1" },
        { label: "EMEA (Frankfurt)", id: "eu-central-1" },
        { label: "EMEA (Dublin)", id: "eu-west-1" },
        { label: "EMEA (London)", id: "eu-west-2" },
        { label: "EMEA (Paris)", id: "eu-west-3" },
        { label: "Americas (São Paulo)", id: "sa-east-1" },
        { label: "Americas (US West)", id: "us-west-2" },
        { label: "Americas (US East)", id: "us-east-1" }
    ],
    EdgeAutoUpdateRRuleOptions: [
        { label: "Daily", id: "FREQ=DAILY" },
        { label: "Weekly", id: "FREQ=WEEKLY" }
    ]
};

// DIDs
const DIDsConstants = {
    ImportJobs: 'did-import-jobs',
    ProviderOptions: [
        { label: "PureCloud", id: "PURE_CLOUD" },
        { label: "PureCloud Voice", id: "PURE_CLOUD_VOICE" }
    ]
};

// Extensions
const ExtensionsConstants = {
    ImportJobs: 'extensionpools-import-jobs'
};

// Phone Base Settings
const PhoneBaseSettingsConstants = {
    ImportJobs: 'phonebasesettings-import-jobs',
    HardwareTypeOptions: [{label: 'mac' }],
    MediaCodecsOptions: [
        {label: 'audio/opus'},
        {label: 'audio/pcmu'},
        {label: 'audio/pcma'}
    ]
};

// Phones
const PhonesConstants = {
    ImportJobs: 'phones-import-jobs'
};

// Schedules
const SchedulesConstants = {
    ImportJobs: 'schedules-import-jobs',
    RRuleTypeOptions: [
        { label: "Once", id: "ONCE" },
        { label: "Run to Occurrences", id: "COUNT={X}" },
        { label: "Run Until", id: "UNTIL={X}" },
        { label: "Forever", id: "FOREVER" }
    ],
    rruleSelectOptions: [
        { label: "Yearly", id: "FREQ=YEARLY" },
        { label: "Monthly", id: "FREQ=MONTHLY" },
        { label: "Weekly", id: "FREQ=WEEKLY" },
        { label: "Daily", id: "FREQ=DAILY" }
    ],
    MonthOptions: [
        { label: "Jan", id: "1" },
        { label: "Feb", id: "2" },
        { label: "Mar", id: "3" },
        { label: "Apr", id: "4" },
        { label: "May", id: "5" },
        { label: "Jun", id: "6" },
        { label: "Jul", id: "7" },
        { label: "Aug", id: "8" },
        { label: "Sep", id: "9" },
        { label: "Oct", id: "10" },
        { label: "Nov", id: "11" },
        { label: "Dec", id: "12" }        
    ]
};

// Schedule Groups
const ScheduleGroupsConstants = {
    ImportJobs: 'schedule-groups-import-jobs'
};

// Emergency Groups
const EmergencyGroupsConstants = {
    ImportJobs: 'emergency-groups-import-jobs'
};

// Ivr Routing
const IvrRoutingConstants = {
    ImportJobs: 'ivr-routing-import-jobs'
};

// Widgets
const WidgetsConstants = {
    ImportJobs: 'widgets-import-jobs',
    ClientTypeOptions: [
        { label: "Version 2", id: "v2", description: "Supports Predictive Engagement." },
        { label: "Version 1.1", id: "v1-http", description: "Supports authenticated chat and HTTP chat APIs. This does not support Predictive Engagement." },
        { label: "Version 1.0", id: "v1", description: "Supports authenticated chat, screen sharing, and co-browse. This does not support Predictive Engagement." },
        { label: "Third Party", id: "third-party", description: "Support for web chat widgets not developed by Genesys. This does not support Predictive Engagement." }
    ]
};

// Prompts
const PromptsConstants = {
    ImportJobs: 'prompts-import-jobs'
};

// Callflow Templates
const CallflowTemplatesConstants = {
    ImportJobs: 'callflow-templates-import-jobs'
};

// Callflow Flows
const CallflowFlowsConstants = {
    ImportJobs: 'callflow-flows-import-jobs',
    CallflowTypeOptions: [
        { label: "Inbound Call", id: "INBOUNDCALL" },
        { label: "Inbound Message", id: "INBOUNDSHORTMESSAGE" },
        { label: "Inbound Email", id: "INBOUNDEMAIL" },
        { label: "Inbound Chat", id: "INBOUNDCHAT" },
        { label: "Outbound Call", id: "OUTBOUNDCALL" },
        { label: "Common Module", id: "COMMONMODULE" },
        { label: "In-Queue Call", id: "INQUEUECALL" },
        { label: "In-Queue Email", id: "INQUEUEEMAIL" },
        { label: "In-Queue Message", id: "INQUEUESHORTMESSAGE" },
        { label: "Secure Call", id: "SECURECALL" },
        { label: "Voicemail", id: "VOICEMAIL" },
        { label: "Workflow", id: "WORKFLOW" },
        { label: "Survey Invite", id: "SURVEYINVITE" }
    ]
};

// Shared
const SharedConstants = {
    TimeZoneOptions: [
        { label: "Africa/Cairo ", short: "(+02:00)", id: 1 },
        { label: "Africa/Casablanca", short: "(+00:00)", id: 2 },
        { label: "Africa/Harare", short: "(+02:00)", id: 3 },
        { label: "Africa/Luanda", short: "(+01:00)", id: 4 },
        { label: "Africa/Nairobi", short: "(+03:00)", id: 5 },
        { label: "Africa/Tripoli", short: "(+02:00)", id: 6 },
        { label: "Africa/Windhoek", short: "(+02:00)", id: 7 },
        { label: "America/Anchorage", short: "(-08:00)", id: 8 },
        { label: "America/Argentina/Cordoba", short: "(-03:00)", id: 9 },
        { label: "America/Asuncion", short: "(-04:00)", id: 10 },
        { label: "America/Bahia", short: "(-03:00)", id: 11 },
        { label: "America/Bogota", short: "(-05:00)", id: 12 },
        { label: "America/Buenos_Aires", short: "(-03:00)", id: 13 },
        { label: "America/Cancun", short: "(-05:00)", id: 14 },
        { label: "America/Caracas", short: "(-04:00)", id: 15 },
        { label: "America/Chicago", short: "(-05:00)", id: 16 },
        { label: "America/Chihuahua", short: "(-06:00)", id: 17 },
        { label: "America/Denver", short: "(-06:00)", id: 18 },
        { label: "America/Godthab", short: "(-02:00)", id: 19 },
        { label: "America/Guatemala", short: "(-06:00)", id: 20 },
        { label: "America/Halifax", short: "(-03:00)", id: 21 },
        { label: "America/Indianapolis", short: "(-04:00)", id: 22 },
        { label: "America/Los_Angeles", short: "(-07:00)", id: 23 },
        { label: "America/Manaus", short: "(-04:00)", id: 24 },
        { label: "America/Mexico_City", short: "(-06:00)", id: 25 },
        { label: "America/Montevideo", short: "(-03:00)", id: 26 },
        { label: "America/New_York", short: "(-04:00)", id: 27 },
        { label: "America/Phoenix", short: "(-07:00)", id: 28 },
        { label: "America/Regina", short: "(-06:00)", id: 29 },
        { label: "America/Santiago", short: "(-04:00)", id: 30 },
        { label: "America/Sao_Paulo", short: "(-03:00)", id: 31 },
        { label: "America/St_Johns", short: "(-02:30)", id: 32 },
        { label: "America/Tijuana", short: "(-07:00)", id: 33 },
        { label: "Asia/Amman", short: "(+03:00)", id: 34 },
        { label: "Asia/Baghdad", short: "(+03:00)", id: 35 },
        { label: "Asia/Baku", short: "(+04:00)", id: 36 },
        { label: "Asia/Bangkok", short: "(+07:00)", id: 37 },
        { label: "Asia/Barnaul", short: "(+07:00)", id: 38 },
        { label: "Asia/Beirut", short: "(+03:00)", id: 39 },
        { label: "Asia/Calcutta", short: "(+05:30)", id: 40 },
        { label: "Asia/Chita", short: "(+09:00)", id: 41 },
        { label: "Asia/Colombo", short: "(+05:30)", id: 42 },
        { label: "Asia/Dhaka", short: "(+06:00)", id: 43 },
        { label: "Asia/Jerusalem", short: "(+03:00)", id: 44 },
        { label: "Asia/Kabul", short: "(+04:30)", id: 45 },
        { label: "Asia/Kamchatka", short: "(+12:00)", id: 46 },
        { label: "Asia/Karachi", short: "(+05:00)", id: 47 },
        { label: "Asia/Katmandu", short: "(+05:45)", id: 48 },
        { label: "Asia/Krasnoyarsk", short: "(+07:00)", id: 49 },
        { label: "Asia/Magadan", short: "(+11:00)", id: 50 },
        { label: "Asia/Muscat", short: "(+04:00)", id: 51 },
        { label: "Asia/Novosibirsk", short: "(+07:00)", id: 52 },
        { label: "Asia/Pyongyang", short: "(+09:00)", id: 53 },
        { label: "Asia/Rangoon", short: "(+06:30)", id: 54 },
        { label: "Asia/Riyadh", short: "(+03:00)", id: 55 },
        { label: "Asia/Sakhalin", short: "(+11:00)", id: 56 },
        { label: "Asia/Seoul", short: "(+09:00)", id: 57 },
        { label: "Asia/Shanghai", short: "(+08:00)", id: 58 },
        { label: "Asia/Singapore", short: "(+08:00)", id: 59 },
        { label: "Asia/Srednekolymsk", short: "(+11:00)", id: 60 },
        { label: "Asia/Taipei", short: "(+08:00)", id: 61 },
        { label: "Asia/Tbilisi", short: "(+04:00)", id: 62 },
        { label: "Asia/Tehran", short: "(+03:30)", id: 63 },
        { label: "Asia/Tokyo", short: "(+09:00)", id: 64 },
        { label: "Asia/Tomsk", short: "(+07:00)", id: 65 },
        { label: "Asia/Ulaanbaatar", short: "(+08:00)", id: 66 },
        { label: "Asia/Vladivostok", short: "(+10:00)", id: 67 },
        { label: "Asia/Yakutsk", short: "(+09:00)", id: 68 },
        { label: "Asia/Yekaterinburg", short: "(+05:00)", id: 69 },
        { label: "Asia/Yerevan", short: "(+04:00)", id: 70 },
        { label: "Atlantic/Azores", short: "(+00:00)", id: 71 },
        { label: "Atlantic/Cape_Verde", short: "(-01:00)", id: 72 },
        { label: "Atlantic/South_Georgia", short: "(-02:00)", id: 73 },
        { label: "Australia/Adelaide", short: "(+09:30)", id: 74 },
        { label: "Australia/Brisbane", short: "(+10:00)", id: 75 },
        { label: "Australia/Darwin", short: "(+09:30)", id: 76 },
        { label: "Australia/Hobart", short: "(+10:00)", id: 77 },
        { label: "Australia/Perth", short: "(+08:00)", id: 78 },
        { label: "Australia/Sydney", short: "(+10:00)", id: 79 },
        { label: "Europe/Astrakhan", short: "(+04:00)", id: 80 },
        { label: "Europe/Athens", short: "(+03:00)", id: 81 },
        { label: "Europe/Belgrade", short: "(+02:00)", id: 82 },
        { label: "Europe/Berlin", short: "(+02:00)", id: 83 },
        { label: "Europe/Helsinki", short: "(+03:00)", id: 84 },
        { label: "Europe/Istanbul", short: "(+03:00)", id: 85 },
        { label: "Europe/Kaliningrad", short: "(+02:00)", id: 86 },
        { label: "Europe/London", short: "(+01:00)", id: 87 },
        { label: "Europe/Minsk", short: "(+03:00)", id: 88 },
        { label: "Europe/Moscow", short: "(+03:00)", id: 89 },
        { label: "Europe/Paris", short: "(+02:00)", id: 90 },
        { label: "Europe/Prague", short: "(+02:00)", id: 91 },
        { label: "Europe/Samara", short: "(+04:00)", id: 92 },
        { label: "GMT", short: "(+00:00)", id: 93 },
        { label: "GMT-12:00", short: "(+00:00)", id: 94 },
        { label: "Indian/Mauritius", short: "(+04:00)", id: 95 },
        { label: "Pacific/Apia", short: "(+13:00)", id: 96 },
        { label: "Pacific/Auckland", short: "(+12:00)", id: 97 },
        { label: "Pacific/Fiji", short: "(+12:00)", id: 98 },
        { label: "Pacific/Guadalcanal", short: "(+11:00)", id: 99 },
        { label: "Pacific/Guam", short: "(+10:00)", id: 100 },
        { label: "Pacific/Honolulu", short: "(-10:00)", id: 101 },
        { label: "Pacific/Kiritimati", short: "(+14:00)", id: 102 },
        { label: "Pacific/Norfolk", short: "(+11:00)", id: 103 },
        { label: "Pacific/Tongatapu", short: "(+13:00)", id: 104 }
    ],
    LanguageOptions: [
        { label: "Afrikaans (Namibia)", id: "af-na" },
        { label: "Afrikaans (South Africa)", id: "af-za" },
        { label: "Aghem (Cameroon)", id: "agq-cm" },
        { label: "Akan (Ghana)", id: "ak-gh" },
        { label: "Amharic (Ethiopia)", id: "am-et" },
        { label: "Arabic (World)", id: "ar-001" },
        { label: "Arabic (United Arab Emirates)", id: "ar-ae" },
        { label: "Arabic (Bahrain)", id: "ar-bh" },
        { label: "Arabic (Djibouti)", id: "ar-dj" },
        { label: "Arabic (Algeria)", id: "ar-dz" },
        { label: "Arabic (Egypt)", id: "ar-eg" },
        { label: "Arabic (Western Sahara)", id: "ar-eh" },
        { label: "Arabic (Eritrea)", id: "ar-er" },
        { label: "Arabic (Israel)", id: "ar-il" },
        { label: "Arabic (Iraq)", id: "ar-iq" },
        { label: "Arabic (Jordan)", id: "ar-jo" },
        { label: "Arabic (Comoros)", id: "ar-km" },
        { label: "Arabic (Kuwait)", id: "ar-kw" },
        { label: "Arabic (Lebanon)", id: "ar-lb" },
        { label: "Arabic (Libya)", id: "ar-ly" },
        { label: "Arabic (Morocco)", id: "ar-ma" },
        { label: "Arabic (Mauritania)", id: "ar-mr" },
        { label: "Arabic (Oman)", id: "ar-om" },
        { label: "Arabic (Palestinian Territories)", id: "ar-ps" },
        { label: "Arabic (Qatar)", id: "ar-qa" },
        { label: "Arabic (Saudi Arabia)", id: "ar-sa" },
        { label: "Arabic (Sudan)", id: "ar-sd" },
        { label: "Arabic (Somalia)", id: "ar-so" },
        { label: "Arabic (Syria)", id: "ar-sy" },
        { label: "Arabic (Chad)", id: "ar-td" },
        { label: "Arabic (Tunisia)", id: "ar-tn" },
        { label: "Arabic (Yemen)", id: "ar-ye" },
        { label: "Mapudungun (Chile)", id: "arn-cl" },
        { label: "Assamese (India)", id: "as-in" },
        { label: "Asu (Tanzania)", id: "asa-tz" },
        { label: "Azerbaijani (Cyrillic)", id: "az-cyrl" },
        { label: "Azerbaijani (Cyrillic, Azerbaijan)", id: "az-cyrl-az" },
        { label: "Azerbaijani (Latin)", id: "az-latn" },
        { label: "Azerbaijani (Latin, Azerbaijan)", id: "az-latn-az" },
        { label: "Bashkir (Russia)", id: "ba-ru" },
        { label: "Basaa (Cameroon)", id: "bas-cm" },
        { label: "Belarusian (Belarus)", id: "be-by" },
        { label: "Bemba (Zambia)", id: "bem-zm" },
        { label: "Bena (Tanzania)", id: "bez-tz" },
        { label: "Bulgarian (Bulgaria)", id: "bg-bg" },
        { label: "Bambara (Mali)", id: "bm-ml" },
        { label: "Bengali (Bangladesh)", id: "bn-bd" },
        { label: "Bengali (India)", id: "bn-in" },
        { label: "Tibetan (China)", id: "bo-cn" },
        { label: "Tibetan (India)", id: "bo-in" },
        { label: "Breton (France)", id: "br-fr" },
        { label: "Bosnian (Cyrillic)", id: "bs-cyrl" },
        { label: "Bosnian (Cyrillic, Bosnia and Herzegovina)", id: "bs-cyrl-ba" },
        { label: "Bosnian (Latin)", id: "bs-latn" },
        { label: "Bosnian (Latin, Bosnia and Herzegovina)", id: "bs-latn-ba" },
        { label: "Catalan (Andorra)", id: "ca-ad" },
        { label: "Catalan (Spain)", id: "ca-es" },
        { label: "Chiga (Uganda)", id: "cgg-ug" },
        { label: "Cherokee (United States)", id: "chr-us" },
        { label: "Corsican (France)", id: "co-fr" },
        { label: "Czech (Czech Republic)", id: "cs-cz" },
        { label: "Welsh (United Kingdom)", id: "cy-gb" },
        { label: "Danish (Denmark)", id: "da-dk" },
        { label: "Taita (Kenya)", id: "dav-ke" },
        { label: "German (Austria)", id: "de-at" },
        { label: "German (Belgium)", id: "de-be" },
        { label: "German (Switzerland)", id: "de-ch" },
        { label: "German (Germany)", id: "de-de" },
        { label: "German (Liechtenstein)", id: "de-li" },
        { label: "German (Luxembourg)", id: "de-lu" },
        { label: "Zarma (Niger)", id: "dje-ne" },
        { label: "Lower Sorbian (Germany)", id: "dsb-de" },
        { label: "Duala (Cameroon)", id: "dua-cm" },
        { label: "Divehi (Maldives)", id: "dv-mv" },
        { label: "Jola-Fonyi (Senegal)", id: "dyo-sn" },
        { label: "Dzongkha (Bhutan)", id: "dz-bt" },
        { label: "Embu (Kenya)", id: "ebu-ke" },
        { label: "Ewe (Ghana)", id: "ee-gh" },
        { label: "Ewe (Togo)", id: "ee-tg" },
        { label: "Greek (Cyprus)", id: "el-cy" },
        { label: "Greek (Greece)", id: "el-gr" },
        { label: "English (Caribbean)", id: "en-029" },
        { label: "English (Europe)", id: "en-150" },
        { label: "English (Antigua and Barbuda)", id: "en-ag" },
        { label: "English (United States)", id: "en-us" },
        { label: "English (American Samoa)", id: "en-as" },
        { label: "English (Australia)", id: "en-au" },
        { label: "English (Barbados)", id: "en-bb" },
        { label: "English (Belgium)", id: "en-be" },
        { label: "English (Bermuda)", id: "en-bm" },
        { label: "English (Bahamas)", id: "en-bs" },
        { label: "English (Botswana)", id: "en-bw" },
        { label: "English (Belize)", id: "en-bz" },
        { label: "English (Canada)", id: "en-ca" },
        { label: "English (Cameroon)", id: "en-cm" },
        { label: "English (Dominica)", id: "en-dm" },
        { label: "English (Fiji)", id: "en-fj" },
        { label: "English (Micronesia)", id: "en-fm" },
        { label: "English (United Kingdom)", id: "en-gb" },
        { label: "English (Wales)", id: "en-gb-wls" },
        { label: "English (Grenada)", id: "en-gd" },
        { label: "English (Guernsey)", id: "en-gg" },
        { label: "English (Ghana)", id: "en-gh" },
        { label: "English (Gibraltar)", id: "en-gi" },
        { label: "English (Gambia)", id: "en-gm" },
        { label: "English (Guam)", id: "en-gu" },
        { label: "English (Guyana)", id: "en-gy" },
        { label: "English (Hong Kong SAR China)", id: "en-hk" },
        { label: "English (Ireland)", id: "en-ie" },
        { label: "English (Isle of Man)", id: "en-im" },
        { label: "English (India)", id: "en-in" },
        { label: "English (Jersey)", id: "en-je" },
        { label: "English (Jamaica)", id: "en-jm" },
        { label: "English (Kenya)", id: "en-ke" },
        { label: "English (Kiribati)", id: "en-ki" },
        { label: "English (Saint Kitts and Nevis)", id: "en-kn" },
        { label: "English (Cayman Islands)", id: "en-ky" },
        { label: "English (Saint Lucia)", id: "en-lc" },
        { label: "English (Liberia)", id: "en-lr" },
        { label: "English (Lesotho)", id: "en-ls" },
        { label: "English (Madagascar)", id: "en-mg" },
        { label: "English (Marshall Islands)", id: "en-mh" },
        { label: "English (Northern Mariana Islands)", id: "en-mp" },
        { label: "English (Malta)", id: "en-mt" },
        { label: "English (Mauritius)", id: "en-mu" },
        { label: "English (Malawi)", id: "en-mw" },
        { label: "English (Malaysia)", id: "en-my" },
        { label: "English (Namibia)", id: "en-na" },
        { label: "English (Nigeria)", id: "en-ng" },
        { label: "English (New Zealand)", id: "en-nz" },
        { label: "English (Papua New Guinea)", id: "en-pg" },
        { label: "English (Philippines)", id: "en-ph" },
        { label: "English (Pakistan)", id: "en-pk" },
        { label: "English (Puerto Rico)", id: "en-pr" },
        { label: "English (Palau)", id: "en-pw" },
        { label: "English (Solomon Islands)", id: "en-sb" },
        { label: "English (Seychelles)", id: "en-sc" },
        { label: "English (Singapore)", id: "en-sg" },
        { label: "English (Sierra Leone)", id: "en-sl" },
        { label: "English (South Sudan)", id: "en-ss" },
        { label: "English (Swaziland)", id: "en-sz" },
        { label: "English (Turks and Caicos Islands)", id: "en-tc" },
        { label: "English (Tonga)", id: "en-to" },
        { label: "English (Trinidad and Tobago)", id: "en-tt" },
        { label: "English (Tanzania)", id: "en-tz" },
        { label: "English (Uganda)", id: "en-ug" },
        { label: "English (U.S. Outlying Islands)", id: "en-um" },
        { label: "English (Saint Vincent and the Grenadines)", id: "en-vc" },
        { label: "English (British Virgin Islands)", id: "en-vg" },
        { label: "English (U.S. Virgin Islands)", id: "en-vi" },
        { label: "English (Vanuatu)", id: "en-vu" },
        { label: "English (Samoa)", id: "en-ws" },
        { label: "English (South Africa)", id: "en-za" },
        { label: "English (Zambia)", id: "en-zm" },
        { label: "English (Zimbabwe)", id: "en-zw" },
        { label: "Spanish (Latin America)", id: "es-419" },
        { label: "Spanish (Argentina)", id: "es-ar" },
        { label: "Spanish (Bolivia)", id: "es-bo" },
        { label: "Spanish (Chile)", id: "es-cl" },
        { label: "Spanish (Colombia)", id: "es-co" },
        { label: "Spanish (Costa Rica)", id: "es-cr" },
        { label: "Spanish (Cuba)", id: "es-cu" },
        { label: "Spanish (Dominican Republic)", id: "es-do" },
        { label: "Spanish (Ceuta and Melilla)", id: "es-ea" },
        { label: "Spanish (Ecuador)", id: "es-ec" },
        { label: "Spanish (Spain)", id: "es-es" },
        { label: "Spanish (Equatorial Guinea)", id: "es-gq" },
        { label: "Spanish (Guatemala)", id: "es-gt" },
        { label: "Spanish (Honduras)", id: "es-hn" },
        { label: "Spanish (Canary Islands)", id: "es-ic" },
        { label: "Spanish (Mexico)", id: "es-mx" },
        { label: "Spanish (Nicaragua)", id: "es-ni" },
        { label: "Spanish (Panama)", id: "es-pa" },
        { label: "Spanish (Peru)", id: "es-pe" },
        { label: "Spanish (Philippines)", id: "es-ph" },
        { label: "Spanish (Puerto Rico)", id: "es-pr" },
        { label: "Spanish (Paraguay)", id: "es-py" },
        { label: "Spanish (El Salvador)", id: "es-sv" },
        { label: "Spanish (United States)", id: "es-us" },
        { label: "Spanish (Uruguay)", id: "es-uy" },
        { label: "Spanish (Venezuela)", id: "es-ve" },
        { label: "Estonian (Estonia)", id: "et-ee" },
        { label: "Basque (Spain)", id: "eu-es" },
        { label: "Ewondo (Cameroon)", id: "ewo-cm" },
        { label: "Persian (Afghanistan)", id: "fa-af" },
        { label: "Persian (Iran)", id: "fa-ir" },
        { label: "Fulah (Senegal)", id: "ff-sn" },
        { label: "Finnish (Finland)", id: "fi-fi" },
        { label: "Filipino (Philippines)", id: "fil-ph" },
        { label: "Faroese (Faroe Islands)", id: "fo-fo" },
        { label: "French (Belgium)", id: "fr-be" },
        { label: "French (Burkina Faso)", id: "fr-bf" },
        { label: "French (Burundi)", id: "fr-bi" },
        { label: "French (Benin)", id: "fr-bj" },
        { label: "French (Saint Barthélemy)", id: "fr-bl" },
        { label: "French (Canada)", id: "fr-ca" },
        { label: "French (Congo - Kinshasa)", id: "fr-cd" },
        { label: "French (Central African Republic)", id: "fr-cf" },
        { label: "French (Congo - Brazzaville)", id: "fr-cg" },
        { label: "French (Switzerland)", id: "fr-ch" },
        { label: "French (Côte d'Ivoire)", id: "fr-ci" },
        { label: "French (Cameroon)", id: "fr-cm" },
        { label: "French (Djibouti)", id: "fr-dj" },
        { label: "French (Algeria)", id: "fr-dz" },
        { label: "French (France)", id: "fr-fr" },
        { label: "French (Gabon)", id: "fr-ga" },
        { label: "French (French Guiana)", id: "fr-gf" },
        { label: "French (Guinea)", id: "fr-gn" },
        { label: "French (Guadeloupe)", id: "fr-gp" },
        { label: "French (Equatorial Guinea)", id: "fr-gq" },
        { label: "French (Haiti)", id: "fr-ht" },
        { label: "French (Comoros)", id: "fr-km" },
        { label: "French (Luxembourg)", id: "fr-lu" },
        { label: "French (Morocco)", id: "fr-ma" },
        { label: "French (Monaco)", id: "fr-mc" },
        { label: "French (Saint Martin)", id: "fr-mf" },
        { label: "French (Madagascar)", id: "fr-mg" },
        { label: "French (Mali)", id: "fr-ml" },
        { label: "French (Martinique)", id: "fr-mq" },
        { label: "French (Mauritania)", id: "fr-mr" },
        { label: "French (Mauritius)", id: "fr-mu" },
        { label: "French (New Caledonia)", id: "fr-nc" },
        { label: "French (Niger)", id: "fr-ne" },
        { label: "French (French Polynesia)", id: "fr-pf" },
        { label: "French (Réunion)", id: "fr-re" },
        { label: "French (Rwanda)", id: "fr-rw" },
        { label: "French (Seychelles)", id: "fr-sc" },
        { label: "French (Senegal)", id: "fr-sn" },
        { label: "French (Syria)", id: "fr-sy" },
        { label: "French (Chad)", id: "fr-td" },
        { label: "French (Togo)", id: "fr-tg" },
        { label: "French (Tunisia)", id: "fr-tn" },
        { label: "French (Vanuatu)", id: "fr-vu" },
        { label: "French (Mayotte)", id: "fr-yt" },
        { label: "Frisian (Netherlands)", id: "fy-nl" },
        { label: "Irish (Ireland)", id: "ga-ie" },
        { label: "Scottish Gaelic (United Kingdom)", id: "gd-gb" },
        { label: "Galician (Spain)", id: "gl-es" },
        { label: "Swiss German (Switzerland)", id: "gsw-ch" },
        { label: "Alsatian (France)", id: "gsw-fr" },
        { label: "Gujarati (India)", id: "gu-in" },
        { label: "Gusii (Kenya)", id: "guz-ke" },
        { label: "Manx (United Kingdom)", id: "gv-gb" },
        { label: "Hausa (Latin)", id: "ha-latn" },
        { label: "Hausa (Latin, Ghana)", id: "ha-latn-gh" },
        { label: "Hausa (Latin, Niger)", id: "ha-latn-ne" },
        { label: "Hausa (Latin, Nigeria)", id: "ha-latn-ng" },
        { label: "Hausa-Latin (Nigeria)", id: "ha-ng" },
        { label: "Hawaiian (United States)", id: "haw-us" },
        { label: "Hebrew (Israel)", id: "he-il" },
        { label: "Hindi (India)", id: "hi-in" },
        { label: "Croatian (Bosnia and Herzegovina)", id: "hr-ba" },
        { label: "Croatian (Croatia)", id: "hr-hr" },
        { label: "Upper Sorbian (Germany)", id: "hsb-de" },
        { label: "Hungarian (Hungary)", id: "hu-hu" },
        { label: "Armenian (Armenia)", id: "hy-am" },
        { label: "Indonesian (Indonesia)", id: "id-id" },
        { label: "Igbo (Nigeria)", id: "ig-ng" },
        { label: "Sichuan Yi (China)", id: "ii-cn" },
        { label: "Icelandic (Iceland)", id: "is-is" },
        { label: "Italian (Switzerland)", id: "it-ch" },
        { label: "Italian (Italy)", id: "it-it" },
        { label: "Italian (San Marino)", id: "it-sm" },
        { label: "Japanese (Japan)", id: "ja-jp" },
        { label: "Ngomba (Cameroon)", id: "jgo-cm" },
        { label: "Machame (Tanzania)", id: "jmc-tz" },
        { label: "Georgian (Georgia)", id: "ka-ge" },
        { label: "Kabyle (Algeria)", id: "kab-dz" },
        { label: "Kamba (Kenya)", id: "kam-ke" },
        { label: "Makonde (Tanzania)", id: "kde-tz" },
        { label: "Kabuverdianu (Cape Verde)", id: "kea-cv" },
        { label: "Koyra Chiini (Mali)", id: "khq-ml" },
        { label: "Kikuyu (Kenya)", id: "ki-ke" },
        { label: "Kazakh (Cyrillic)", id: "kk-cyrl" },
        { label: "Kazakh (Cyrillic, Kazakhstan)", id: "kk-cyrl-kz" },
        { label: "Kazakh (Kazakhstan)", id: "kk-kz" },
        { label: "Kalaallisut (Greenland)", id: "kl-gl" },
        { label: "Kalenjin (Kenya)", id: "kln-ke" },
        { label: "Khmer (Cambodia)", id: "km-kh" },
        { label: "Kannada (India)", id: "kn-in" },
        { label: "Korean (North Korea)", id: "ko-kp" },
        { label: "Korean (South Korea)", id: "ko-kr" },
        { label: "Konkani (India)", id: "kok-in" },
        { label: "Kashmiri (Arabic)", id: "ks-arab" },
        { label: "Kashmiri (Arabic, India)", id: "ks-arab-in" },
        { label: "Shambala (Tanzania)", id: "ksb-tz" },
        { label: "Bafia (Cameroon)", id: "ksf-cm" },
        { label: "Cornish (United Kingdom)", id: "kw-gb" },
        { label: "Kyrgyz (Kyrgyzstan)", id: "ky-kg" },
        { label: "Langi (Tanzania)", id: "lag-tz" },
        { label: "Luxembourgish (Luxembourg)", id: "lb-lu" },
        { label: "Ganda (Uganda)", id: "lg-ug" },
        { label: "Lingala (Angola)", id: "ln-ao" },
        { label: "Lingala (Congo - Kinshasa)", id: "ln-cd" },
        { label: "Lingala (Central African Republic)", id: "ln-cf" },
        { label: "Lingala (Congo - Brazzaville)", id: "ln-cg" },
        { label: "Lao (Laos)", id: "lo-la" },
        { label: "Lithuanian (Lithuania)", id: "lt-lt" },
        { label: "Luba-Katanga (Congo - Kinshasa)", id: "lu-cd" },
        { label: "Luo (Kenya)", id: "luo-ke" },
        { label: "Luyia (Kenya)", id: "luy-ke" },
        { label: "Latvian (Latvia)", id: "lv-lv" },
        { label: "Masai (Kenya)", id: "mas-ke" },
        { label: "Masai (Tanzania)", id: "mas-tz" },
        { label: "Meru (Kenya)", id: "mer-ke" },
        { label: "Morisyen (Mauritius)", id: "mfe-mu" },
        { label: "Malagasy (Madagascar)", id: "mg-mg" },
        { label: "Makhuwa-Meetto (Mozambique)", id: "mgh-mz" },
        { label: "Meta' (Cameroon)", id: "mgo-cm" },
        { label: "Maori (New Zealand)", id: "mi-nz" },
        { label: "Macedonian (Macedonia)", id: "mk-mk" },
        { label: "Malayalam (India)", id: "ml-in" },
        { label: "Mongolian-Traditional Mongolian (People's Republic of China)", id: "mn-cn" },
        { label: "Mongolian-Cyrillic (Mongolia)", id: "mn-mn" },
        { label: "Mohawk (Canada)", id: "moh-ca" },
        { label: "Marathi (India)", id: "mr-in" },
        { label: "Malay (Brunei)", id: "ms-bn" },
        { label: "Malay (Malaysia)", id: "ms-my" },
        { label: "Malay (Singapore)", id: "ms-sg" },
        { label: "Maltese (Malta)", id: "mt-mt" },
        { label: "Mundang (Cameroon)", id: "mua-cm" },
        { label: "Burmese (Myanmar (Burma))", id: "my-mm" },
        { label: "Nama (Namibia)", id: "naq-na" },
        { label: "Norwegian Bokmål (Norway)", id: "nb-no" },
        { label: "North Ndebele (Zimbabwe)", id: "nd-zw" },
        { label: "Nepali (India)", id: "ne-in" },
        { label: "Nepali (Nepal)", id: "ne-np" },
        { label: "Dutch (Aruba)", id: "nl-aw" },
        { label: "Dutch (Belgium)", id: "nl-be" },
        { label: "Dutch (Curaçao)", id: "nl-cw" },
        { label: "Dutch (Netherlands)", id: "nl-nl" },
        { label: "Dutch (Suriname)", id: "nl-sr" },
        { label: "Kwasio (Cameroon)", id: "nmg-cm" },
        { label: "Norwegian Nynorsk (Norway)", id: "nn-no" },
        { label: "Sesotho sa Leboa (South Africa)", id: "nso-za" },
        { label: "Nuer (Sudan)", id: "nus-sd" },
        { label: "Nyankole (Uganda)", id: "nyn-ug" },
        { label: "Occitan (France)", id: "oc-fr" },
        { label: "Oromo (Ethiopia)", id: "om-et" },
        { label: "Oromo (Kenya)", id: "om-ke" },
        { label: "Oriya (India)", id: "or-in" },
        { label: "Punjabi (Arabic)", id: "pa-arab" },
        { label: "Punjabi (Arabic, Pakistan)", id: "pa-arab-pk" },
        { label: "Punjabi (Gurmukhi)", id: "pa-guru" },
        { label: "Punjabi (Gurmukhi, India)", id: "pa-guru-in" },
        { label: "Punjabi (India)", id: "pa-in" },
        { label: "Polish (Poland)", id: "pl-pl" },
        { label: "Dari (Afghanistan)", id: "prs-af" },
        { label: "Pashto (Afghanistan)", id: "ps-af" },
        { label: "Portuguese (Angola)", id: "pt-ao" },
        { label: "Portuguese (Brazil)", id: "pt-br" },
        { label: "Portuguese (Cape Verde)", id: "pt-cv" },
        { label: "Portuguese (Guinea-Bissau)", id: "pt-gw" },
        { label: "Portuguese (Macau SAR China)", id: "pt-mo" },
        { label: "Portuguese (Mozambique)", id: "pt-mz" },
        { label: "Portuguese (Portugal)", id: "pt-pt" },
        { label: "Portuguese (São Tomé and Príncipe)", id: "pt-st" },
        { label: "Portuguese (Timor-Leste)", id: "pt-tl" },
        { label: "K'iche (Guatemala)", id: "qut-gt" },
        { label: "Quechua (Bolivia)", id: "quz-bo" },
        { label: "Quechua (Ecuador)", id: "quz-ec" },
        { label: "Quechua (Peru)", id: "quz-pe" },
        { label: "Romansh (Switzerland)", id: "rm-ch" },
        { label: "Rundi (Burundi)", id: "rn-bi" },
        { label: "Romanian (Moldova)", id: "ro-md" },
        { label: "Romanian (Romania)", id: "ro-ro" },
        { label: "Rombo (Tanzania)", id: "rof-tz" },
        { label: "Russian (Belarus)", id: "ru-by" },
        { label: "Russian (Kyrgyzstan)", id: "ru-kg" },
        { label: "Russian (Kazakhstan)", id: "ru-kz" },
        { label: "Russian (Moldova)", id: "ru-md" },
        { label: "Russian (Russia)", id: "ru-ru" },
        { label: "Russian (Ukraine)", id: "ru-ua" },
        { label: "Kinyarwanda (Rwanda)", id: "rw-rw" },
        { label: "Rwa (Tanzania)", id: "rwk-tz" },
        { label: "Sanskrit (India)", id: "sa-in" },
        { label: "Yakut (Russia)", id: "sah-ru" },
        { label: "Samburu (Kenya)", id: "saq-ke" },
        { label: "Sangu (Tanzania)", id: "sbp-tz" },
        { label: "Sami-Northern (Finland)", id: "se-fi" },
        { label: "Sami-Northern (Norway)", id: "se-no" },
        { label: "Sami-Northern (Sweden)", id: "se-se" },
        { label: "Sena (Mozambique)", id: "seh-mz" },
        { label: "Koyraboro Senni (Mali)", id: "ses-ml" },
        { label: "Sango (Central African Republic)", id: "sg-cf" },
        { label: "Tachelhit (Latin)", id: "shi-latn" },
        { label: "Tachelhit (Latin, Morocco)", id: "shi-latn-ma" },
        { label: "Tachelhit (Tifinagh)", id: "shi-tfng" },
        { label: "Tachelhit (Tifinagh, Morocco)", id: "shi-tfng-ma" },
        { label: "Sinhala (Sri Lanka)", id: "si-lk" },
        { label: "Slovak (Slovakia)", id: "sk-sk" },
        { label: "Slovenian (Slovenia)", id: "sl-si" },
        { label: "Sami-Southern (Norway)", id: "sma-no" },
        { label: "Sami-Southern (Sweden)", id: "sma-se" },
        { label: "Sami-Lule (Norway)", id: "smj-no" },
        { label: "Sami-Lule (Sweden)", id: "smj-se" },
        { label: "Sami-Inari (Finland)", id: "smn-fi" },
        { label: "Sami-Skolt (Finland)", id: "sms-fi" },
        { label: "Shona (Zimbabwe)", id: "sn-zw" },
        { label: "Somali (Djibouti)", id: "so-dj" },
        { label: "Somali (Ethiopia)", id: "so-et" },
        { label: "Somali (Kenya)", id: "so-ke" },
        { label: "Somali (Somalia)", id: "so-so" },
        { label: "Albanian (Albania)", id: "sq-al" },
        { label: "Albanian (Macedonia)", id: "sq-mk" },
        { label: "Serbian (Cyrillic)", id: "sr-cyrl" },
        { label: "Serbian (Cyrillic, Bosnia and Herzegovina)", id: "sr-cyrl-ba" },
        { label: "Serbian-Cyrillic (Serbia and Montenegro (Former))", id: "sr-cyrl-cs" },
        { label: "Serbian (Cyrillic, Montenegro)", id: "sr-cyrl-me" },
        { label: "Serbian (Cyrillic, Serbia)", id: "sr-cyrl-rs" },
        { label: "Serbian (Latin)", id: "sr-latn" },
        { label: "Serbian (Latin, Bosnia and Herzegovina)", id: "sr-latn-ba" },
        { label: "Serbian-Latin (Serbia and Montenegro (Former))", id: "sr-latn-cs" },
        { label: "Serbian (Latin, Montenegro)", id: "sr-latn-me" },
        { label: "Serbian (Latin, Serbia)", id: "sr-latn-rs" },
        { label: "Swedish (Finland)", id: "sv-fi" },
        { label: "Swedish (Sweden)", id: "sv-se" },
        { label: "Swahili (Kenya)", id: "sw-ke" },
        { label: "Swahili (Tanzania)", id: "sw-tz" },
        { label: "Swahili (Uganda)", id: "sw-ug" },
        { label: "Congo Swahili (Congo - Kinshasa)", id: "swc-cd" },
        { label: "Syriac (Syria)", id: "syr-sy" },
        { label: "Tamil (India)", id: "ta-in" },
        { label: "Tamil (Sri Lanka)", id: "ta-lk" },
        { label: "Tamil (Malaysia)", id: "ta-my" },
        { label: "Tamil (Singapore)", id: "ta-sg" },
        { label: "Telugu (India)", id: "te-in" },
        { label: "Teso (Kenya)", id: "teo-ke" },
        { label: "Teso (Uganda)", id: "teo-ug" },
        { label: "Tajik-Cyrillic (Tajikistan)", id: "tg-tj" },
        { label: "Thai (Thailand)", id: "th-th" },
        { label: "Tigrinya (Eritrea)", id: "ti-er" },
        { label: "Tigrinya (Ethiopia)", id: "ti-et" },
        { label: "Turkmen (Turkmenistan)", id: "tk-tm" },
        { label: "Setswana (South Africa)", id: "tn-za" },
        { label: "Tongan (Tonga)", id: "to-to" },
        { label: "Turkish (Cyprus)", id: "tr-cy" },
        { label: "Turkish (Turkey)", id: "tr-tr" },
        { label: "Tatar (Russia)", id: "tt-ru" },
        { label: "Tasawaq (Niger)", id: "twq-ne" },
        { label: "Tamazight-Latin (Algeria)", id: "tzm-dz" },
        { label: "Central Atlas Tamazight (Latin)", id: "tzm-latn" },
        { label: "Central Atlas Tamazight (Latin, Morocco)", id: "tzm-latn-ma" },
        { label: "Uyghur (People's Republic of China)", id: "ug-cn" },
        { label: "Ukrainian (Ukraine)", id: "uk-ua" },
        { label: "Urdu (India)", id: "ur-in" },
        { label: "Urdu (Pakistan)", id: "ur-pk" },
        { label: "Uzbek (Arabic)", id: "uz-arab" },
        { label: "Uzbek (Arabic, Afghanistan)", id: "uz-arab-af" },
        { label: "Uzbek (Cyrillic)", id: "uz-cyrl" },
        { label: "Uzbek (Cyrillic, Uzbekistan)", id: "uz-cyrl-uz" },
        { label: "Uzbek (Latin)", id: "uz-latn" },
        { label: "Uzbek (Latin, Uzbekistan)", id: "uz-latn-uz" },
        { label: "Vai (Latin)", id: "vai-latn" },
        { label: "Vai (Latin, Liberia)", id: "vai-latn-lr" },
        { label: "Vai (Vai)", id: "vai-vaii" },
        { label: "Vai (Vai, Liberia)", id: "vai-vaii-lr" },
        { label: "Vietnamese (Vietnam)", id: "vi-vn" },
        { label: "Vunjo (Tanzania)", id: "vun-tz" },
        { label: "Wolof (Senegal)", id: "wo-sn" },
        { label: "Yangben (Cameroon)", id: "yav-cm" },
        { label: "Yoruba (Nigeria)", id: "yo-ng" },
        { label: "Chinese-Simplified (People's Republic of China)", id: "zh-cn" },
        { label: "Chinese (Simplified)", id: "zh-hans" },
        { label: "Chinese (Simplified, China)", id: "zh-hans-cn" },
        { label: "Chinese (Simplified, Hong Kong SAR China)", id: "zh-hans-hk" },
        { label: "Chinese (Simplified, Macau SAR China)", id: "zh-hans-mo" },
        { label: "Chinese (Simplified, Singapore)", id: "zh-hans-sg" },
        { label: "Chinese (Traditional)", id: "zh-hant" },
        { label: "Chinese (Traditional, Hong Kong SAR China)", id: "zh-hant-hk" },
        { label: "Chinese (Traditional, Macau SAR China)", id: "zh-hant-mo" },
        { label: "Chinese (Traditional, Taiwan)", id: "zh-hant-tw" },
        { label: "Chinese-Traditional (Hong Kong S.A.R.)", id: "zh-hk" },
        { label: "Chinese-Traditional (Macao S.A.R.)", id: "zh-mo" },
        { label: "Chinese-Simplified (Singapore)", id: "zh-sg" },
        { label: "Chinese-Traditional (Taiwan)", id: "zh-tw" },
        { label: "Zulu (South Africa)", id: "zu-za" }
    ],
    ScheduleRepeatOptions: [
        { label: 'Daily', id: 1 },
        { label: 'Weekly', id: 2 },
        { label: 'Monthly', id: 3 },
        //{ label: 'Yearly', id: 4 }
    ],
    ScheduleTypeOptions: [
        { label: 'Schedule', id: 1 },
        { label: 'Adhoc', id: 2 }
    ],
    ScheduleDayOptions: [...Array(28).keys()].map(m => {
        return { label: `${m + 1}`, id: m + 1 };
    }),
    ScheduleMonthOptions: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'].map((m, i) => {
        return { label: `${m}`, id: i + 1 };
    })
};

// Queues
const QueuesConstants = {
    ImportJobs: 'queue-import-jobs',
    ACWOptions: [
        { label: "Mandatory", id: "MANDATORY" },
        { label: "Optional", id: "OPTIONAL" },
        { label: "Mandatory Timeout", id: "MANDATORY_TIMEOUT" },
        { label: "Mandatory Forced Timout", id: "MANDATORY_FORCED_TIMEOUT" },
        { label: "Agent Requested", id: "AGENT_REQUESTED" },
    ],
    SkillEvaluationOptions: [
        { label: "None", id: "NONE" },
        { label: "Best", id: "BEST" },
        { label: "All", id: "ALL" },
    ],
    ScoringMethodOptions: [
        { label: "Timestamp And Priority", id: "TimestampAndPriority" },
        { label: "Priority Only", id: "PriorityOnly" },
    ]
};

const UsersConstants = {
    ImportJobs: 'user-import-jobs',
    MediaTypeOptions: [
        { label: "Phone", id: "PHONE" },
        { label: "Email", id: "EMAIL" },
        { label: "SMS", id: "SMS" }
    ],
    AddressTypeOptions: [
        { label: "Primary", id: "PRIMARY" },
        { label: "Work", id: "WORK" },
        { label: "Work2", id: "WORK2" },
        { label: "Work3", id: "WORK3" },
        { label: "Work4", id: "WORK4" },
        { label: "Home", id: "HOME" },
        { label: "Mobile", id: "Mobile" },
        { label: "Main", id: "MAIN" },
        { label: "Other", id: "OTHER" }
    ]
};

const GroupsConstants = {
    ImportJobs: 'groups-import-jobs',
    GroupTypeOptions: [
        { label: "Official", id: "official" },
        { label: "Social", id: "social" }
    ],
    GroupVisibilityOptions: [
        { label: "Members Only", id: "members" },
        { label: "Owners Only", id: "owners" },
        { label: "public", id: "public" }
    ]
};

// Imports
const ImportConstants = {
    ImportModel: 'import-model',
    displayOrder: ['divisions', 'division', 'roles', 'role', 'skills', 'skill', 'wrapupcodes', 'wrapupcode',
        'locations', 'location', 'sites', 'site', 'queues', 'queue', 'users', 'user', 'groups', 'group',
        'didpools', 'didpool', 'extensionpools', 'extensionpool', 'phonebasesettingss', 'phonebasesettings',
        'phones', 'phone', 'schedules', 'schedule', 'schedulegroups', 'schedulegroup', 'flows', 'flow',
        'widgets', 'widget', 'ivrs', 'ivr', 'emergencygroups', 'emergencygroup', 'prompts', 'prompt']

}

const ImportDataOriginConstants = {
    GenesysCloud: 'genesyscloud',
    PureConnect: 'pureconnect',
    Engage: 'engage'
};

const ImportDataEntityTypes = {
    Divisions: 'divisions',
    Roles: 'roles',
    Skills: 'skills',
    WrapUpCodes: 'wrapupcodes',
    Sites: 'sites',
    Locations: 'locations',
    Queues: 'queues',
    Users: 'users',
    Groups: 'groups',
    DIDs: 'didpools',
    Extensions: 'extensionpools',
    PhoneBaseSettings: 'phonebasesettingss',
    Phones: 'phones',
    Schedules: 'schedules',
    ScheduleGroups: 'schedulegroups',
    EmergencyGroups: 'emergencygroups',
    IVRs: 'ivrs',
    Widgets: 'widgets',
    Prompts: 'prompts',
    CallflowTemplates: 'callflowtemplates',
    Flows: 'flows'
};
    
// CX
const CXCode = {
    DisasterRecoveryJobTypeSchedule: 'schedule',
    DisasterRecoveryJobTypeAdhoc: 'adhoc',
    DisasterRecoveryDestination: 'dr-des',
    DisasterRecoveryAnalysisComplete: 'dr-analysis-complete',
    DisasterRecoverySetupComplete: 'dr-setup-complete',
    DisasterRecoveryAnalysisJobInfo: 'dr-variable-job',
    DisasterRecoveryVariables: 'dr-variables',
    DisasterRecoveryVariablesDate: 'dr-variables-date'
};

const AuditConstants = {
    ActionIgnoreList: [],
    APIActionIgnoreList: []
};

// Exports

const ExportConstants = {
    ExportSelectionComplete: 'export-selection-complete',
    ExportSelectionModel: 'export-selection-model',
    ExportIntervalLock: 'export-interval-lock',
    ExportProcessingJobs: 'export-processing-jobs'
}

export {
    AuthenticationConstants,
    OrganizationConstants,
    IgniteConstants,
    JobsConstants,
    DivisionsConstants,
    SkillsConstants,
    WrapUpCodesConstants,
    RolesConstants,
    LocationsConstants,
    SitesConstants,
    DIDsConstants,
    ExtensionsConstants,
    QueuesConstants,
    UsersConstants,
    GroupsConstants,
    PhoneBaseSettingsConstants,
    PhonesConstants,
    SchedulesConstants,
    ScheduleGroupsConstants,
    EmergencyGroupsConstants,
    IvrRoutingConstants,
    WidgetsConstants,
    PromptsConstants,
    CallflowTemplatesConstants,
    CallflowFlowsConstants,
    SharedConstants,
    ImportConstants,
    ImportDataOriginConstants,
    ImportDataEntityTypes,
    CXCode,
    AuditConstants,
    ExportConstants
}