import { fetchWithRetries, baseUri, fetchWrap, rawFetchGetFile } from 'common/utilities/fetchWrap';

const getDetails = async (url) => {
    const standardResponse = {
        success: false,
        status: 0,
        data: {},
        headers: {},
        url: ''
    };

    const response = await fetch(url, {
        method: 'GET'
    });

    // need to no-content returns
    if (response.status === 204) {
        standardResponse.data = {};
    } else {
        try {
            standardResponse.data = await response.json();
        } catch(e) {
            console.log(e);
        }
        
    }

    standardResponse.status = response.status;
    standardResponse.url = response.url;

    response.headers.forEach((value, key) => {
        Object.assign(standardResponse.headers, { [key]: value });
    });

    if (!response.ok) { return Promise.reject(standardResponse); };
    standardResponse.success = true;
    return standardResponse;
};

/**
 * @description Gets the current list of jobs for the user based on the users current session.
 * @return {Array} 
 */
export const getJobs = () => {
    const url = `${baseUri()}/sessions/me/jobs`;
    return fetchWithRetries(url, {
        method: 'GET'
    }, '', false, true);
}

/**
 * @description Gets the current job based on the passed job id.
 * @param {number} id
 * @return {Object} 
 */
export const getJob = (id) => {
    const url = `${baseUri()}/jobs/${id}`;
    return fetchWithRetries(url, {
        method: 'GET'
    }, '', false, true);
}

/**
 * @description Gets the current list of jobs based on the passed cron name.
 * @param {string} cronName
 * @return {Object} 
 */
export const getJobsForCron = (cronName) => {
    const url = `${baseUri()}/jobs/scheduled/${cronName}`;
    return fetchWithRetries(url, {
        method: 'GET'
    }, '', false, true);
}

/**
 * @async
 * @description Gets the current presigned url content for import details. 
 * @param {*} url
 * @return {Promise<Object>} 
 */
export const getJobImportDetails = async (url) => {
    return await getDetails(url);
};

/**
 * @async
 * @description Gets the current presigned url content for dr sync details. 
 * @param {*} url
 * @return {Promise<Object>} 
 */
export const getJobDrSyncDetails = async (url) => {
    const getDetails = async (url) => {
        const standardResponse = {
            success: false,
            status: 0,
            data: {},
            headers: {},
            url: ''
        };

        const response = await fetch(url, {
            method: 'GET'
        });

        // need to no-content returns
        if (response.status === 204) {
            standardResponse.data = {};
        } else {
            try {
                standardResponse.data = await response.text();
            } catch (e) {
                console.log(e);
            }

        }

        standardResponse.status = response.status;
        standardResponse.url = response.url;

        response.headers.forEach((value, key) => {
            Object.assign(standardResponse.headers, { [key]: value });
        });

        if (!response.ok) { return Promise.reject(standardResponse); };
        standardResponse.success = true;
        return standardResponse;
    };


    return await getDetails(url);
};

export const getJobExport = () => {
    const url = `${baseUri()}/entities/export`;
    return fetchWithRetries(url, {
        method: 'GET'
    }, '', false, true);
};

export const postJobImport = () => {
    const url = `${baseUri()}/entities/import`;
    return fetchWithRetries(url, {
        method: 'POST'
    }, '', false, true);
};

export const patchFinalizeJob = (jobId, fields) => {
    const url = `${baseUri()}/jobs/${jobId}/finalize`;
    return fetchWithRetries(url, {
        method: 'PATCH',
        body: JSON.stringify({
            ...fields
        })
    }, '', false, true);
};

export const getZipFile = (url) => {
    return rawFetchGetFile(url);
}

export default {
    getJobs,
    getJob,
    getJobImportDetails,
    getJobExport,
    postJobImport,
    patchFinalizeJob,
    getZipFile
}