import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';

import {
    List,
    ListItemIcon, ListItemText,
    ListItemButton,
    Collapse, Tooltip
} from '@mui/material';

import { ExpandLessOutlined, ExpandMoreOutlined, CycloneOutlined, CloudSyncOutlined, ScheduleOutlined } from '@mui/icons-material';


const CXAsCodeSidebar = () => {
    const isMounted = useRef(true);
    const navigate = useNavigate();
    const location = useLocation();

    const [cxascodeToggle, setCXAsCodeToggle] = useState(true);

    useEffect(() => {
        if (isMounted && location && location.pathname !== '') {
            if (location.pathname === '/') {
                setCXAsCodeToggle(false);
            } else if (location.pathname.includes('cxnow/') || location.pathname.includes('cxnow')) {
                setCXAsCodeToggle(true);
            }
        }

        return () => {
            isMounted.current = false;
        }
    }, [location.pathname])

    const handleCXEvoToggle = (event) => {
        event.preventDefault();
        if (!location.pathname.includes('cxnow') || location.pathname.includes('cxnow/')) {
            navigate('/cxnow');
            setCXAsCodeToggle(true);
        } else {
            setCXAsCodeToggle(!cxascodeToggle);
        }
    }

    return (
        <Fragment>
            <ListItemButton component={Link} key={'CXAsCode'} selected={location.pathname.includes('cxnow')} onClick={handleCXEvoToggle}>
                <ListItemIcon>
                    <CloudSyncOutlined />
                </ListItemIcon>
                <Tooltip placement='top' title='Business Continuity Plan'>
                    <ListItemText primary={'BCP Wizard'} />
                </Tooltip>
                {cxascodeToggle ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
            </ListItemButton>
            <Collapse in={cxascodeToggle} timeout="auto">
                <List component="div" disablePadding>
                    {/* <ListItemButton component={Link} to={`/cxnow/backup`} key={'CXNow-Backup'} selected={location.pathname.includes('cxnow/backup')} sx={{ pl: 4 }}>
                        <ListItemIcon>
                            <Backup />
                        </ListItemIcon>
                        <ListItemText primary="Backup (WIP)" />
                    </ListItemButton>
                    <ListItemButton component={Link} to={`/cxnow/export`} key={'CXNow-Export'} selected={location.pathname.includes('cxnow/export')} sx={{ pl: 4 }}>
                        <ListItemIcon>
                            <GetApp />
                        </ListItemIcon>
                        <ListItemText primary="Export (WIP)" />
                    </ListItemButton>
                    <ListItemButton component={Link} to={`/cxnow/import`} key={'CXNow-Import'} selected={location.pathname.includes('cxnow/import')} sx={{ pl: 4 }}>
                        <ListItemIcon>
                            <Upgrade />
                        </ListItemIcon>
                        <ListItemText primary="Import (WIP)" />
                    </ListItemButton> */}
                    <ListItemButton component={Link} to={`/cxnow/disaster-recovery`} key={'CXNow-DRSync'} selected={location.pathname.includes('cxnow/disaster-recovery')} sx={{ pl: 4 }}>
                        <ListItemIcon>
                            <CycloneOutlined />
                        </ListItemIcon>
                        <ListItemText primary="Disaster Recovery Sync" />
                    </ListItemButton>
                    <ListItemButton component={Link} to={`/cxnow/schedules-list`} key={'CXNow-Schedules'} selected={location.pathname.includes('cxnow/schedules-list')} sx={{ pl: 4 }}>
                        <ListItemIcon>
                            <ScheduleOutlined />
                        </ListItemIcon>
                        <ListItemText primary="Scheduled Syncs" />
                    </ListItemButton>
                    {/* for the future */}
                    {/* <ListItemButton component={Link} to={`/cxnow/org-validation`} key={'CXNow-Validation'} selected={location.pathname.includes('cxnow/org-validation')} sx={{ pl: 4 }}>
                        <ListItemIcon>
                            <Checklist />
                        </ListItemIcon>
                        <ListItemText primary="Organization Validation" />
                    </ListItemButton> */}

                    {/* <ListItemButton component={Link} to={`/cxnow/selective-sync`} key={'CXNow-SSync'} selected={location.pathname.includes('cxnow/selective-sync')} sx={{ pl: 4 }}>
                        <ListItemIcon>
                            <SyncAlt />
                        </ListItemIcon>
                        <ListItemText primary="Selective Sync" />
                    </ListItemButton>
                    <ListItemButton component={Link} to={`/cxnow/trail-feature`} key={'CXNow-TrailFeature'} selected={location.pathname.includes('cxnow/trail-feature')} sx={{ pl: 4 }}>
                        <ListItemIcon>
                            <Construction />
                        </ListItemIcon>
                        <ListItemText primary="Trial Feature" />
                    </ListItemButton>
                    <ListItemButton component={Link} to={`/cxnow/deploy-feature`} key={'CXNow-DeployFeature'} selected={location.pathname.includes('cxnow/deploy-feature')} sx={{ pl: 4 }}>
                        <ListItemIcon>
                            <InstallDesktop />
                        </ListItemIcon>
                        <ListItemText primary="Deploy Feature" />
                    </ListItemButton> */}
                </List>
            </Collapse>
        </Fragment>
    )
};

export default CXAsCodeSidebar;