import { useSnackbar } from 'notistack';
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/material/SvgIcon/SvgIcon";
import * as React from "react";
import { Close } from '@mui/icons-material';


const useNotification = () => {
    const [conf, setConf] = React.useState({});
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const action = key => (
        <React.Fragment>
            <IconButton onClick={() => { closeSnackbar(key) }}>
                <Close />
            </IconButton>
        </React.Fragment>
    );

    React.useEffect(() => {
        if (conf?.msg) {
            let variant = 'info';
            let duration = 6000;

            if (conf.variant) {
                variant = conf.variant;
            }

            if (conf.duration) {
                duration = conf.duration;
            }

            enqueueSnackbar(conf.msg, {
                variant: variant,
                autoHideDuration: duration,
                anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
                action
            });
        }
    }, [conf]);

    return [setConf];
};

export default useNotification;