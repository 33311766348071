import { fetchWithRetries, baseUri, fetchWrap } from 'common/utilities/fetchWrap';

/**
 * @async
 * @description Gets the current organization information.
 * @return {Object} 
 */
export const getOrgMe = () => {
    const url = `${baseUri()}/organizations/me`;
    return fetchWithRetries(url, {
        method: 'GET'
    }, '', false, true);
};

/**
 * @description Post the current options to the organization endpoint.
 * @return {Object} 
 */
export const postOrganization = (fields) => {
    const url = `${baseUri()}/organizations`;
    return fetchWithRetries(url, {
        method: 'POST',
        body: JSON.stringify({
            ...fields
        })
    }, '', false, true);
};

/**
 * @description Gets the current organization information using the raw token implicit grant for destination auth.
 * @return {Object} 
 */
export const getDestinationOrgMe = (token) => {
    // pull destination org information from secure storage.
    const url = `${baseUri()}/organizations/me`;
    return fetchWithRetries(url, {
        method: 'GET'
    }, token, true, false);
};

/**
 * @description Analyzes the current organization and then we need to 
 * @return {Object} 
 */
export const postAnalyzeOrg = (type) => {
    const url = `${baseUri()}/organizations/me/analysis`;
    return fetchWithRetries(url, {
        method: 'POST',
        body: JSON.stringify({
            type: type
        })
    }, '', true, true);
};

/**
 * @description Gets the current organization information from appfoundry.
 * @return {Object} 
 */
export const getAFOrganization = () => {
    const url = `${baseUri()}/organizations/af/me`;
    return fetchWrap(url, {
        method: 'GET'
    }, '', false, true);
};

/**
 * @description Post the current organization information only once due to checking for organization existence. Can be expected to fail in cases where the org is not registered.
 * @return {Object} 
 */
export const postAFOrganization = (fields) => {
    const url = `${baseUri()}/organizations/af`;
    return fetchWrap(url, {
        method: 'POST',
        body: JSON.stringify({
            ...fields
        })
    }, '', false, true);
};


export default {
    getOrgMe,
    getDestinationOrgMe,
    postAnalyzeOrg,
    postOrganization,
    getAFOrganization,
    postAFOrganization
}