// Third Party
import moment from "moment"
import { v4 as uuidv4 } from 'uuid';
import { isEmpty } from 'lodash';

// RFC 2822 Email Validation
const uuidRegex = new RegExp("^[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}$", "i");
const urlRegex = new RegExp("https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&\\/\\/=]*)", "i");
const rfcEmail = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i;

// Standardized Date Format
const standard = 'MMM Do YYYY';
const standardWithTime = 'MMM Do YYYY, h:mm:ss a';
const standardFile = 'MMM-Do-YYYY'
const standardTimeOnly = 'h:mm:ss a';

export const validateEmail = (value) => {
    return rfcEmail.test(value.toLowerCase());
};

export const validateUUID = (value) => {
    return uuidRegex.test(value);
};

export const validateURL = (value) => {
    return urlRegex.test(value);
};

export const copyToClipboard = (value) => {
    navigator.clipboard.writeText(value);
};

export const extractInfoFromS3ObjectKey = (jobDetailsS3ObjectKey) => {
    // jobDetailsS3ObjectKey: "e47fe28a-8942-4ad7-9549-e57b63a9388b/dr_sync/d3ace0af-840f-444b-ae08-686b59e68a18/stdout/2024-02-15T12:05:15.384Z-job_output.json"
    // match three sequences of characters separated by '/'
    const pattern = /^([^\/]+\/[^\/]+\/[^\/]+)\/.*/
    return jobDetailsS3ObjectKey.replace(pattern, '$1') // returns "e47fe28a-8942-4ad7-9549-e57b63a9388b/dr_sync/d3ace0af-840f-444b-ae08-686b59e68a18"
};

export const generateSolutionName = (value) => {
    if (value) {
        return value.toLowerCase().replaceAll(' ', '-');
    }
    return value;
};

export const parseDayField = (value) => {
    if (value) {
        const temp = parseInt(value, 10);
        if (temp > 31) return 31;
        if (temp < 1) return 1;
        return temp;
    } else {
        return 1;
    }
};

export const parseMonthField = (value) => {
    if (value) {
        const temp = parseInt(value, 10);
        if (temp > 12) return 12;
        if (temp < 1) return 1;
        return temp;
    } else {
        return 1;
    }
};

export const filterEmptyObjects = (arr) => {
    return arr.filter(f => !isEmpty(f));
};

export const momentDateCompare = (v1, v2, withTime = false) => {
    if (v1 && v2) {
        const v1m = moment(v1, withTime ? standardWithTime : standard);
        const v2m = moment(v2, withTime ? standardWithTime : standard);
        if (v1m.isBefore(v2m)) {
            return 1;
        } else if (v1m.isAfter(v2m)) {
            return -1;
        }
        return 0;
    }
    else if (v1) {
        return 1;
    }
    else if (v2) {
        return -1;
    }
    return 0;
};

export const getDisplayDates = (v1) => {
    if (v1 === null) return { standard: '', standardWithTime: '', standardTimeOnly: '', fromNow: '' }
    return Object.assign({}, {
        standard: v1.indexOf('Z') > -1 ? moment(v1).format(standard) : moment(v1, standard).format(standard),
        standardWithTime: v1.indexOf('Z') > -1 ? moment(v1).format(standardWithTime) : moment(v1, standardWithTime).format(standardWithTime),
        standardTimeOnly: v1.indexOf('Z') > -1 ? moment(v1).format(standardTimeOnly) : moment(v1, standardTimeOnly).format(standardTimeOnly),
        fromNow: moment(v1).fromNow()
    })
};

export const getFileMoment = () => {
    return moment().format(standardFile);
};

export const downloadPreSignedUrl = (url, downloadName) => {
    const a = document.createElement("a");
    a.href = url;
    a.download = `${downloadName}`;

    const clickEvent = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
    });

    a.dispatchEvent(clickEvent);
    a.remove();
};

export const keyify = (obj = {}, prefix = '', skipFn = false) => {
    if (skipFn) {
        return [];
    }
    return Object.keys(obj)
        .reduce(
            (res, el) => {
                if (prefix !== '' && res.indexOf(prefix.substring(0, prefix.length - 1)) < 0) {
                    res.push(prefix.substring(0, prefix.length - 1));
                }
                if (typeof obj[el] === 'object' && obj[el] !== null) {
                    // test to see if it has keys before moving
                    if (Object.keys(obj[el]).length === 0) {
                        return [...res, prefix + el];
                    } else {
                        return [...res, ...keyify(obj[el], prefix + el + '.')];
                    }
                }
                return [...res, prefix + el];
            }, []
        );
};

export const mapValuesToOptions = (shortValue, labelValue, idValue = uuidv4()) => {
    return Object.assign({}, {
        short: shortValue,
        label: labelValue,
        id: idValue
    });
};

export const asJSON = (jsonString, defaultObject = null) => {
    try {
        if (typeof (jsonString) === 'object')
            return jsonString;
        let test = JSON.parse(jsonString);
        return test;
    } catch (e) {
        return defaultObject;
    }
};

export const toTitleCase = (text) => {
    if (text == null) return '';
    return text.toLowerCase().replace(
        /(?<![^\s\p{Pd}])[^\s\p{Pd}]/ug, match => match.toUpperCase()
    );
};

export const dateFromExcelNumber = (excelNumber) => {
    let dayValue = Math.floor(excelNumber) - 1; // -1 for leap year issue
    let hourValue = (excelNumber % 1) * 24;
    return new Date(0,0, dayValue, hourValue);
};

export default {
    validateEmail,
    validateUUID,
    validateURL,
    copyToClipboard,
    extractInfoFromS3ObjectKey,
    generateSolutionName,
    parseDayField,
    parseMonthField,
    momentDateCompare,
    getDisplayDates,
    getFileMoment,
    downloadPreSignedUrl,
    mapValuesToOptions,
    keyify,
    asJSON,
    toTitleCase,
    dateFromExcelNumber
}