// React
import * as React from 'react';
import { Outlet } from 'react-router';

import './RouteWrapper.css';

const RouteWrapper = () => {
    return (
        <React.Fragment>
            <div className='app-route-wrapper'>
                <Outlet />
            </div>
        </React.Fragment>
    );
};

export default RouteWrapper;