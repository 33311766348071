import config from '../../config';

// This is a utility file which uses either a versioned storage or a optional passed key to update storage on a user browser.
// Configuration: the application config is currently looking for a "config.app.storageVersion" located in the config file. example : { app: { storageVersion: 'v1' }}
// The typical usage of this would be to allow for being able to wipe user preferences in the event of a large change that would need for users to reinstate certain variables that have changed.
// However, you can pass a optional true to the functions to allow for bypassing all versioning.

/**
 * @description Sets a specific key and value in the users browser storage.
 * @param {*} key name representing the value
 * @param {*} value object
 * @param {boolean} [noVersion=false] optional skip to versioning of storage
 * @param {Storage} [system=localStorage] storage provider | default localStorage | options: localStorage, sessionStorage
 * @return {void} no return
 */
export const setStorageItem = (key, value, noVersion = false, noSolution = false, system = localStorage) => {
    if (key !== null && key !== undefined) {
        const json = JSON.stringify(value);
        
        let trueKey = `${key}`;
        if (!noVersion) {
            trueKey = `${trueKey}-${config.app.storageVersion}`;
        }

        if (!noSolution) {
            trueKey = `${config.app.solutionKey}-${trueKey}`
        }

        system.setItem(trueKey, json);
    }
};

/**
 * @description Gets a specific value from the users browser storage.
 * @param {*} key name representing the value
 * @param {boolean} [noVersion=false] optional skip to versioning of storage
 * @param {Storage} [system=localStorage] storage provider | default localStorage | options: localStorage, sessionStorage
 * @return {*} object | value
 */
export const getStorageItem = (key, noVersion = false, noSolution = false, system = localStorage) => {
    if (key !== null && key !== undefined) {

        let trueKey = `${key}`;
        if (!noVersion) {
            trueKey = `${trueKey}-${config.app.storageVersion}`;
        }

        if (!noSolution) {
            trueKey = `${config.app.solutionKey}-${trueKey}`
        }

        const json = system.getItem(trueKey);
        return JSON.parse(json);
    }
    return null;
};

/**
 * @description Removes the specific passed key from the users browser storage.
 * @param {*} key name representing the value
 * @param {boolean} [noVersion=false] optional skip to versioning of storage
 * @param {Storage} [system=localStorage] storage provider | default localStorage | options: localStorage, sessionStorage
 * @return {void} no return
 */
export const removeStorageItem = (key, noVersion = false, noSolution = false, system = localStorage) => {
    if (key !== null && key !== undefined) {

        let trueKey = `${key}`;
        if (!noVersion) {
            trueKey = `${trueKey}-${config.app.storageVersion}`;
        }

        if (!noSolution) {
            trueKey = `${config.app.solutionKey}-${trueKey}`
        }

        system.removeItem(trueKey);
    }
};

/**
 * @description Clears the entire storage regardless of other keys present (might have unintended consequences if used).
 * @param {Storage} [system=localStorage] storage provider | default localStorage | options: localStorage, sessionStorage
 * @return {void} no return
 */
export const clearAllStorageItems = (system = localStorage) => {
    if (system.length > 0) {
        system.clear();
    }
};

/**
 * @description Clears the entire storage for only app specific keys. Allows to designate keys for instance that prefix 'app-' or keys with the specific app version. 
 * Also allows for blocking specific keys.
 * @param {Storage} [system=localStorage] storage provider | default localStorage | options: localStorage, sessionStorage
 * @return {void} no return
 */
export const clearAllApplicationStorageSafe = (system = localStorage) => {
    if (system && system.length > 0) {
        var keysToDelete = [];
        Object.entries(system).forEach(f => {
            if (f[0] !== 'app-env' && (f[0].includes(`-${config.app.storageVersion}` || f[0].includes('app-')))) {
                keysToDelete.push(f[0]);
            }
        });
        keysToDelete.forEach(f => removeStorageItem(f, true, true, system));
    }
};

export default {
    setStorageItem,
    getStorageItem,
    removeStorageItem,
    clearAllStorageItems,
    clearAllApplicationStorageSafe
}