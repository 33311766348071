import { fetchWithRetries, baseUri, fetchWrap } from 'common/utilities/fetchWrap';

/**
 * @async
 * @description Gets the current list of subscriptions from the all organizations.
 * @return {Promise<Array>} 
 */
export const getSubscriptions = () => {
    const url = `${baseUri()}/subscriptions`;
    return fetchWithRetries(url, {
        method: 'GET'
    }, '', false, true);
};

/**
 * @async
 * @description ADMIN ONLY Post the current options to the subscription endpoint.
 * @param fields orgId, subscriptionEndDate, orgName, environment, modules, description
 * @return {Promise<Object>} 
 */
export const postSubscription = (fields) => {
    const url = `${baseUri()}/subscriptions`;
    return fetchWithRetries(url, {
        method: 'POST',
        body: JSON.stringify({
            ...fields
        })
    }, '', false, true);
};

/**
 * @async
 * @description ADMIN ONLY Patch the current options to the subscription passed.
 * @param id subscription identifier
 * @param fields subscriptionEndDate, environment, modules, description
 * @return {Promise<Object>} 
 */
export const patchSubscription = (id, fields) => {
    const url = `${baseUri()}/subscriptions/${id}`;
    return fetchWithRetries(url, {
        method: 'PATCH',
        body: JSON.stringify({
            ...fields
        })
    }, '', false, true);
};

/**
 * @async
 * @description ADMIN ONLY Deletes the current subscription.
 * @param id subscription identifier
 * @return {Promise<Object>} 
 */
export const deleteSubscription = (id) => {
    const url = `${baseUri()}/subscriptions/${id}`;
    return fetchWithRetries(url, {
        method: 'DELETE'
    }, '', false, true);
};

export default {
    getSubscriptions,
    postSubscription,
    patchSubscription,
    deleteSubscription
}