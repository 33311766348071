import { fetchWrap, baseUri } from 'common/utilities/fetchWrap';

/**
 * @async
 * @description validates that the current organization has has a valid oauth setup for CX module. Don't ever call with retries.
 * @return {Promise<Object>} 
 */
export const postCXModuleValidate = () => {
    const url = `${baseUri()}/modules/validate`;
    return fetchWrap(url, {
        method: 'POST',
        body: JSON.stringify({})
    }, '', false, true);
};

/**
 * @async
 * @description Post the current options to the modules for license agreements endpoint.
 * @param id module id
 * @param fields empty obj 
 * @return {Promise<Object>} 
 */
export const postModuleLicenseAgreement = (id, fields) => {
    const url = `${baseUri()}/modules/${id}/license-agreement`;
    return fetchWithRetries(url, {
        method: 'POST',
        body: JSON.stringify({
            ...fields
        })
    }, '', false, true);
};

/**
 * @async
 * @description Post the current options to the modules endpoint.
 * @param fields "{ moduleName: "someModuleName" }" 
 * @return {Promise<Object>} 
 */
export const postModule = (id, fields) => {
    const url = `${baseUri()}/modules`;
    return fetchWithRetries(url, {
        method: 'POST',
        body: JSON.stringify({
            ...fields
        })
    }, '', false, true);
};

export default {
    postCXModuleValidate,
    postModuleLicenseAgreement,
    postModule
}