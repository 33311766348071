import * as React from 'react';
import { Route, Routes } from 'react-router-dom';

const OrganizationPage = React.lazy(() => import('pages/organization/Organization'));

const OrganizationSettingsPage = React.lazy(() => import('pages/organization/settings/Settings'));
const OrganizationSettingsDivisionsPage = React.lazy(() => import('pages/organization/settings/divisions/Divisions'));
const OrganizationSettingsSkillsPage = React.lazy(() => import('pages/organization/settings/skills/Skills'));
const OrganizationSettingsWrapUpCodesPage = React.lazy(() => import('pages/organization/settings/wrapup-codes/WrapUpCodes'));
const OrganizationSettingsRolesPage = React.lazy(() => import('pages/organization/settings/roles/Roles'));

const OrganizationLocationsPage = React.lazy(() => import('pages/organization/locations/Locations'));
const OrganizationLocationsLocationsPage = React.lazy(() => import('pages/organization/locations/locations/Locations'));
const OrganizationLocationsSitesPage = React.lazy(() => import('pages/organization/locations/sites/Sites'));

const OrganizationPeoplePage = React.lazy(() => import('pages/organization/people/People'));
const OrganizationPeopleQueuesPage = React.lazy(() => import('pages/organization/people/queues/Queues'));
const OrganizationPeopleUsersPage = React.lazy(() => import('pages/organization/people/users/Users'));
const OrganizationPeopleGroupsPage = React.lazy(() => import('pages/organization/people/groups/Groups'));

const OrganizationOutboundPage = React.lazy(() => import('pages/organization/outbound/Outbound'));

const OrganizationTelephonyPage = React.lazy(() => import('pages/organization/telephony/Telephony'));
const OrganizationDidsPage = React.lazy(() => import('pages/organization/telephony/dids/DIDs'));
const OrganizationExtensionsPage = React.lazy(() => import('pages/organization/telephony/extensions/Extensions'));
const OrganizationEdgeGroupsPage = React.lazy(() => import('pages/organization/telephony/edgeGroups/EdgeGroups'));
const OrganizationPhoneBaseSettingsPage = React.lazy(() => import('pages/organization/telephony/phoneBaseSettings/PhoneBaseSettings'));
const OrganizationPhonesPage = React.lazy(() => import('pages/organization/telephony/phones/Phones'));

const OrganizationAnnouncementsPage = React.lazy(() => import('pages/organization/announcements/Announcements'));
const OrganizationPromptsPage = React.lazy(() => import('pages/organization/announcements/prompts/Prompts'))

const OrganizationCallFlowsPage = React.lazy(() => import('pages/organization/call-flows/CallFlows'));
const OrganizationCallFlowTemplatesPage = React.lazy(() => import('pages/organization/call-flows/templates/Templates'));
const OrganizationCallFlowFlowsPage = React.lazy(() => import('pages/organization/call-flows/flows/Flows'));

const OrganizationSchedulesRoutingPage = React.lazy(() => import('pages/organization/schedules-routing/SchedulesRouting'));
const OrganizationSchedulesRoutingSchedulesPage = React.lazy(() => import('pages/organization/schedules-routing/schedules/Schedules'));
const OrganizationSchedulesRoutingScheduleGroupsPage = React.lazy(() => import('pages/organization/schedules-routing/schedule-groups/ScheduleGroups'));
const OrganizationSchedulesRoutingEmergencyGroupsPage = React.lazy(() => import('pages/organization/schedules-routing/emergency-groups/EmergencyGroups'))
const OrganizationSchedulesRoutingIvrRoutingPage = React.lazy(() => import('pages/organization/schedules-routing/ivr-routing/IvrRouting'));
const OrganizationSchedulesRoutingWidgetsPage = React.lazy(() => import('pages/organization/schedules-routing/widgets/Widgets'));

const OrganizationExportPage = React.lazy(() => import('pages/organization/export/Export'));
const OrganizationImportPage = React.lazy(() => import('pages/organization/import/Import'));

const PageNotFoundPage = React.lazy(() => import('pages/PageNotFound'));

const OrganizationRouter = ({ }) => {

    return (
        <Routes>
            <Route index element={<OrganizationPage />} />
            <Route path="settings" element={<OrganizationSettingsPage />}>
                <Route path="divisions" element={<OrganizationSettingsDivisionsPage />} />
                <Route path="skills" element={<OrganizationSettingsSkillsPage />} />
                <Route path="wrapup-codes" element={<OrganizationSettingsWrapUpCodesPage />} />
                <Route path="roles" element={<OrganizationSettingsRolesPage />} />
            </Route>
            <Route path="locations" element={<OrganizationLocationsPage />}>
                <Route path="locations" element={<OrganizationLocationsLocationsPage />} />
                <Route path="sites" element={<OrganizationLocationsSitesPage />} />
            </Route>
            <Route path="people" element={<OrganizationPeoplePage />}>
                <Route path="queues" element={<OrganizationPeopleQueuesPage />} />
                <Route path="users" element={<OrganizationPeopleUsersPage />} />
                <Route path="groups" element={<OrganizationPeopleGroupsPage />} />
            </Route>
            <Route path="telephony" element={<OrganizationTelephonyPage />}>
                <Route path="dids" element={<OrganizationDidsPage />} />
                <Route path="extensions" element={<OrganizationExtensionsPage />} />
                <Route path="edgeGroups" element={<OrganizationEdgeGroupsPage />} />
                <Route path="phoneBaseSettings" element={<OrganizationPhoneBaseSettingsPage />} />
                <Route path="phones" element={<OrganizationPhonesPage />} />
            </Route>
            <Route path="announcements" element={<OrganizationAnnouncementsPage />}>
                <Route path="prompts" element={<OrganizationPromptsPage />} />
            </Route>
            <Route path="callflows" element={<OrganizationCallFlowsPage />}>
                <Route path="templates" element={<OrganizationCallFlowTemplatesPage />} />
                <Route path="flows" element={<OrganizationCallFlowFlowsPage />} />                
            </Route>
            <Route path="schedules-routing" element={<OrganizationSchedulesRoutingPage />}>
                <Route path="schedules" element={<OrganizationSchedulesRoutingSchedulesPage />} />
                <Route path="schedule-groups" element={<OrganizationSchedulesRoutingScheduleGroupsPage />} />
                <Route path="emergency-groups" element={<OrganizationSchedulesRoutingEmergencyGroupsPage />} />
                <Route path="ivr-routing" element={<OrganizationSchedulesRoutingIvrRoutingPage />} />
                <Route path="widgets" element={<OrganizationSchedulesRoutingWidgetsPage />} />
            </Route>
            <Route path='export' element={<OrganizationExportPage />}/>
            {/* <Route path="outbound" element={<OrganizationOutboundPage />} /> */}
            <Route path="import" element={<OrganizationImportPage />} />
            <Route path="*" element={<PageNotFoundPage />} />
        </Routes>
    );
};

export default OrganizationRouter;